import { useState, useEffect, useMemo } from 'react';
import { format } from 'date-fns';

import { createBudget, updateBudget } from '../../services/budget';

import { AppMessage } from '../UI/AppMessage';

// import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

// const useStyles = makeStyles()((theme) => ({

// }));

export const EditBudgetModal = (props: any) => {
  const [budgetKelly, setBudgetKelly] = useState('');
  const [budgetBirkin, setBudgetBirkin] = useState('');

  const [errorBudgetKelly, setErrorBudgetKelly] = useState('');
  const [errorBudgetBirkin, setErrorBudgetBirkin] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const budgetTotal = useMemo(() => Number(budgetKelly || '0') + Number(budgetBirkin || '0'), [budgetKelly, budgetBirkin]);

  const month = useMemo(() => (props.year && props.month ? format(new Date(props.year, props.month, 1), 'MMMM yyyy') : ''), [props.year, props.month]);

  const clearForm = () => {
    // console.log('selectedBudget', props.selectedBudget);
    setBudgetKelly(props.selectedBudget?.kelly || '0');
    setBudgetBirkin(props.selectedBudget?.birkin || '0');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorBudgetKelly('');
    setErrorBudgetBirkin('');
  };

  useEffect(() => {
    clearForm();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedGroup, props.selectedBudget, props.year, props.month])

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (budgetKelly.length === 0) {
      setErrorBudgetKelly(`Enter the Kelly budget for ${month}`);
      return false;
    }
    if (budgetBirkin.length === 0) {
      setErrorBudgetBirkin(`Enter the Birkin budget for ${month}`);
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {

      if (props.selectedBudget) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating Budget for: <strong>${month}</strong> ... ` });
    createBudget(props.selectedGroup?.groupId, props.year, props.month, Number(budgetKelly), Number(budgetBirkin), true).then((result: any) => {
      if (result.status) {
        setMessage({ type: 'success', description: `Budget for: <strong>${month}</strong> created <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(result.data);
      } else {
        setMessage({ type: 'error', description: result.message });
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating Budget for: <strong>${month}</strong> ... ` });
    updateBudget(props.selectedGroup?.groupId, props.year, props.month, Number(budgetKelly), Number(budgetBirkin), true).then((result: any) => {
      if (result.status) {
        setMessage({ type: 'success', description: `Budget for: <strong>${month}</strong> updated <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(result.data);
      } else {
        setMessage({ type: 'error', description: result.message });
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  // const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <DialogTitle>{month} Budget</DialogTitle>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id='budgetKelly'
                label='Kelly'
                value={budgetKelly}
                error={errorBudgetKelly.length > 0}
                helperText={errorBudgetKelly}
                onChange={(e: any) => setBudgetKelly(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='budgetBirkin'
                label='Birkin'
                value={budgetBirkin}
                error={errorBudgetBirkin.length > 0}
                helperText={errorBudgetBirkin}
                onChange={(e: any) => setBudgetBirkin(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <TextField
            id='budgetTotal'
            label={`Total Budget for ${month}`}
            value={budgetTotal}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled
          />

          <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
