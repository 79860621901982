import { EWishType } from '../../services/types';

export const WishTitle = (props: any) => {
  const { wishType } = props;
  switch (wishType) {
    case EWishType.wish: return <>Leather Wish</>;
    case EWishType.appointment: return <>Leather Appointment</>;
  }
  return <></>;
};
