import { useState } from "react";
import { makeStyles } from 'tss-react/mui';

import { AppBarLayout } from "./AppBar";
import { DrawerLayout } from "./Drawer";

const drawerWidth = 264;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
  },
  content: {
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(9, 2, 3, 2),
    },
    padding: theme.spacing(3),
    height: "100vh",
    minHeight: "100vh",
    background: theme.palette.background.default,
    overflowY: "scroll",
    scrollbarWidth: "thin",
    flexGrow: 1
  },
}));

export const AppLayout = (props: any) => {
  const [mobileOpen, setMobileOpen] = useState(localStorage.getItem('mobileOpen') === 'true' ? true : false);

  const setOpen = (val: boolean) => {
    localStorage.setItem('mobileOpen', val.toString());
    setMobileOpen(val);
  }

  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <AppBarLayout mobileOpen={mobileOpen} setMobileOpen={setOpen} drawerWidth={drawerWidth} />
      <DrawerLayout mobileOpen={mobileOpen} setMobileOpen={setOpen} drawerWidth={drawerWidth} />
      <main className={classes.content}>
        {props.children}
      </main>
    </div>
  );
}