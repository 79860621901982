import useSWR from 'swr';

import {useAuthStore} from "../context/useAuthStore";
import { getUsers } from "../../services/users";


const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

const apiUserList = ([_]: any[]) => getUsers();

export const useApiUserList = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['useApiUserList'] : null, apiUserList, options)
  return {
    data: data,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};

