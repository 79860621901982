import { EState } from '../../services/types';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import PublishIcon from '@mui/icons-material/Publish';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import BlockIcon from '@mui/icons-material/Block';
import PendingIcon from '@mui/icons-material/Pending';

export const WishActionIcon = (props: any) => {
  const { selectedWish, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState } = props;
  return (
    <>
      {selectedWish?.state === EState.created && (<Tooltip title="submit"><IconButton color="primary" onClick={() => handleSubmit(selectedWish.wishId)}><PublishIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.submitted && (<Tooltip title="review"><IconButton color="primary" onClick={() => handleReview(selectedWish.wishId)}><VisibilityIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.reviewed && (<Tooltip title="approve"><IconButton color="primary" onClick={() => handleApprove(selectedWish.wishId)}><ThumbUpIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.approved && (<Tooltip title="schedule"><IconButton color="primary" onClick={() => handleSchedule(selectedWish.wishId)}><CalendarMonthIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.scheduled && (<Tooltip title="deliver"><IconButton color="primary" onClick={() => handleDeliver(selectedWish.wishId)}><DoneAllIcon /></IconButton></Tooltip>)}
      {selectedWish?.state === EState.scheduled && (<Tooltip title="cancel"><IconButton color="primary" onClick={() => handleCancel(selectedWish.wishId)}><BlockIcon /></IconButton></Tooltip>)}
      {
        (selectedWish?.state === EState.completed ||
          selectedWish?.state === EState.rejected ||
          selectedWish?.state === EState.denied ||
          selectedWish?.state === EState.expired ||
          selectedWish?.state === EState.cancelled ||
          selectedWish?.state === EState.failed) &&
        (<Tooltip title="update state"><IconButton color="primary" onClick={() => handleState(selectedWish.wishId)}><PendingIcon /></IconButton></Tooltip>)
      }
    </>
  );
};
