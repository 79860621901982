import { EState } from '../../services/types';

import { makeStyles } from 'tss-react/mui';
import Chip from '@mui/material/Chip';

const useStyles = makeStyles()((theme) => ({
  chip: {
    minWidth: '90px',
    textTransform: 'uppercase',
    fontWeight: 500
  }
}));

export const WishState = (props: any) => {
  const { classes } = useStyles();
  switch (props.state) {
    case EState.created: return <Chip color='info' size='small' label='created' className={classes.chip} />;
    case EState.enqueued: return <Chip color='default' size='small' label='proceeding' className={classes.chip} />;
    case EState.pending: return <Chip color='default' size='small' label='proceeding' className={classes.chip} />;
    case EState.processing: return <Chip color='default' size='small' label='processing' className={classes.chip} />;
    case EState.submitted: return <Chip color='info' size='small' label='submitted' className={classes.chip} />;
    case EState.reviewed: return <Chip color='info' size='small' label='reviewed' className={classes.chip} />;
    case EState.approved: return <Chip color='info' size='small' label='approved' className={classes.chip} />;
    case EState.scheduled: return <Chip color='info' size='small' label='scheduled' className={classes.chip} />;
    case EState.completed: return <Chip color='success' size='small' label='completed' className={classes.chip} />;
    case EState.cancelled: return <Chip color='error' size='small' label='cancelled' className={classes.chip} />;
    case EState.rejected: return <Chip color='warning' size='small' label='rejected' className={classes.chip} />;
    case EState.denied: return <Chip color='warning' size='small' label='denied' className={classes.chip} />;
    case EState.expired: return <Chip color='warning' size='small' label='expired' className={classes.chip} />;
    case EState.failed: return <Chip color='error' size='small' label='failed' className={classes.chip} />;
  }
  return <Chip color='default' label={props.state} />;
};
