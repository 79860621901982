import { useState } from 'react';
import { unblockUser } from "../../../services/users";

import { AppMessage } from '../../../shared/UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles()((theme) => ({
  content: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  warning: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.dark,
    fontSize: '0.75rem'
  }
}));

export const UnblockUserModal = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
  };

  const handleSubmit = () => {
    console.log('handleSubmit');
    setLoading(true);
    setMessage({ type: 'warning', description: `Unblock user: <strong>${props.selectedUser?.userId}</strong> ... ` });
    unblockUser(props.selectedUser?.userId).then((data: any) => {
      console.log('Delete User', data);
      if (data) {
        setMessage({ type: 'success', description: `User: <strong>${props.selectedUser?.name}</strong> has been unlocked <small>at ${new Date().toString()}</small>` });
        props.handleSubmit(props.selectedUser?.userId);
        clearErrors();
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Un-Block User</DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Are you sure you want to <strong>un-block</strong> this user:</DialogContentText>
        <div className={classes.content}>
          <h3>{props.selectedUser?.name}</h3>
          <h5>{props.selectedUser?.userId}</h5>
        </div>
        <DialogContentText>This user will be able to login again, and access all their details.</DialogContentText>
        <DialogContentText className={classes.warning}>Warning: Changes may take up to 5 minutes to take effect.</DialogContentText>
        <AppMessage message={message} isLoading={isLoading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary" disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color="primary" variant="contained" disabled={isLoading} disableElevation>Un-block User Account</Button>
      </DialogActions>
    </Dialog>
  );
};
