export enum EState { 
  created = 'CREATED', 
  submitted = 'SUBMITTED', 
  reviewed = 'REVIEWED', 
  approved = 'APPROVED', 
  scheduled = 'SCHEDULED', 
  enqueued = 'ENQUEUED', 
  processing = 'PROCESSING', 
  pending = 'PENDING', 
  completed = 'COMPLETED', 
  cancelled = 'CANCELLED', 
  rejected = 'REJECTED', 
  denied = 'DENIED', 
  failed = 'FAILED', 
  expired = 'EXPIRED', 
};

export enum EWishType { 
  wish = 'wish', 
  appointment = 'appointment', 
}

export enum EBool { 
  true = 'true', 
  false = 'false', 
  undefined = '', 
}

export enum ECategory {
  kelly = 'Kelly Classic',
  kellyx = 'Kelly Exotic',
  birkin = 'Birkin Classic',
  birkinx = 'Birkin Exotic',
}