import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

import { useWishStore } from '../../hooks/context/useWishStore';
import { useAuthStore } from '../../hooks/context/useAuthStore';

import { AppSkeleton } from "../../shared/UI/AppSkeleton";
import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { GroupTable } from '../../shared/Table/GroupTable';
import { WishTable } from './components/WishTable';
import { WishDetails } from '../../shared/Wish/WishDetails';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1, 3, 3, 3),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBlockStart: 0,
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

export const Wishes = () => {
  const { isAuthorized } = useAuthStore();
  const { dataGroupList, dataWishList, selectedGroup, selectGroup, selectedWish, selectWish, isLoading, mutate, handleCreate, handleEdit, handleDelete, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handlePanel } = useWishStore();
  const navigate = useNavigate();
  const location = useLocation();

  const [filterAdvisorUserId, setFilterAdvisorUserId] = useState('');
  const [filterState, setFilterState] = useState('');

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  useEffect(() => {
    if (location?.hash) {
      console.log('hash', location?.hash);
      if (location.hash.indexOf('#') === 0) {
        const filters = new URLSearchParams(location.hash.substring(1));
        setFilterAdvisorUserId(filters.get('advisorUserId') || '');
        setFilterState(filters.get('state') || '');
      }
    }
  }, [location]);

  const wishes = useMemo(() => {
    if (!selectedGroup) return dataWishList;
    return dataWishList?.filter((x: any) => (x.groupId === selectedGroup.groupId));
  }, [selectedGroup, dataWishList]);

  const handleWish = (id: string) => {
    console.log('handleWish', id);
    selectWish(id);
    if (selectedWish) navigate(`/wish/${selectedWish.groupId}/${selectedWish.wishId}`);
  }

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between">
        <Grid item>
          <div className={classes.root}>
            <h1 className={classes.title}>Wishes</h1>
            <h4 className={classes.subtitle}><span>Create</span> &amp; <span>Update</span> Leather Wishes</h4>
          </div>
        </Grid>
        <Grid item style={{ paddingTop: '40px' }}>
          <Button size="small" variant="contained" color="primary" disableElevation endIcon={<LibraryBooksIcon />} className={classes.button} onClick={() => handleCreate('appointment')}>Create Appointment</Button>
          <Button size="small" variant="contained" color="primary" disableElevation endIcon={<PhotoFilterIcon />} className={classes.button} onClick={() => handleCreate('wish')}>Create Wish</Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          {(!Array.isArray(dataGroupList)) && <AppSkeleton className={classes.skeleton} />}
          {(Array.isArray(dataGroupList)) && (
            <Paper className={classes.paper}>
              <GroupTable data={dataGroupList} isLoading={isLoading} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} />
            </Paper>
          )}
          {selectedWish?.wishId && (
            <Paper className={classes.paper}>
              <WishDetails
                isLoading={isLoading}
                mutate={mutate}
                selectedWish={selectedWish}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleSubmit={handleSubmit}
                handleReview={handleReview}
                handleApprove={handleApprove}
                handleSchedule={handleSchedule}
                handleDeliver={handleDeliver}
                handleCancel={handleCancel}
                handleState={handleState}
                handlePanel={handlePanel}
                handleWish={handleWish}
              />
            </Paper>
          )}
        </Grid>
        <Grid item sm={9}>
          {(!Array.isArray(wishes)) && <AppSkeleton className={classes.skeleton} style={{ height: '500px' }} />}
          {(Array.isArray(wishes)) && (
            <Paper className={classes.paper}>
              <WishTable
                data={wishes}
                isLoading={isLoading}
                mutate={mutate}
                selectWish={selectWish}
                selectedWish={selectedWish}
                selectedGroup={selectedGroup}
                filterAdvisorUserId={filterAdvisorUserId}
                filterState={filterState}
                handlePanel={handlePanel}
                handleWish={handleWish}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </AppLayout>
  );
};
