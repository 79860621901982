import { format } from 'date-fns';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  cell: {
    padding: theme.spacing(1),
  },
  label: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right'
  },
  stateLabel: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right',
    marginTop: theme.spacing(0.5)
  },
  text: {
    fontSize: '0.85em',
    fontWeight: 400,
  },
  number: {
    fontSize: '0.85em',
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
    paddingRight: theme.spacing(1),
    textAlign: 'right'
  },
}));

export const WishReview = (props: any) => {
  const { selectedWish } = props;

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}><div className={classes.heading}>SSS Review</div></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow component="tr">
          <TableCell className={classes.cell}>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container spacing={1} style={classes.grid}>
                  <Grid item xs={8} className={classes.label}></Grid><Grid item xs={4} className={classes.text}></Grid>
                  {selectedWish.cegidCreatedAt && (<><Grid item xs={6} className={classes.label}>CEGID Creation: </Grid><Grid item xs={6} className={classes.number}>{format(new Date(selectedWish.cegidCreatedAt), 'dd-MMM-yyyy')}</Grid></>)}
                  <Grid item xs={8} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} style={classes.grid}>
                  <Grid item xs={8} className={classes.label}></Grid><Grid item xs={4} className={classes.text}></Grid>
                  {selectedWish.totalPurchase && (<><Grid item xs={8} className={classes.label}>Total Since Creation: </Grid><Grid item xs={4} className={classes.number}>{selectedWish.totalPurchase}</Grid></>)}
                  {selectedWish.sinceKBCPurchase && (<><Grid item xs={8} className={classes.label}>Total Since KBC: </Grid><Grid item xs={4} className={classes.number}>{selectedWish.sinceKBCPurchase}</Grid></>)}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} style={classes.grid}>
                  <Grid item xs={8} className={classes.label}></Grid><Grid item xs={4} className={classes.text}></Grid>
                  {selectedWish.annualPurchase && (<><Grid item xs={6} className={classes.label}>Total 2025: </Grid><Grid item xs={4} className={classes.number}>{selectedWish.annualPurchase}</Grid></>)}
                  {selectedWish.prevAnnualPurchase && (<><Grid item xs={6} className={classes.label}>Total 2024: </Grid><Grid item xs={4} className={classes.number}>{selectedWish.prevAnnualPurchase}</Grid></>)}
                  <Grid item xs={8} className={classes.label}></Grid><Grid item xs={4} className={classes.text}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} style={classes.grid}>
                  <Grid item xs={8} className={classes.label}></Grid><Grid item xs={4} className={classes.text}></Grid>
                  {selectedWish.annualCPurchase && (<><Grid item xs={8} className={classes.label}>Total C 2025 : </Grid><Grid item xs={4} className={classes.number}>{selectedWish.annualCPurchase}</Grid></>)}
                  {selectedWish.prevAnnualCPurchase && (<><Grid item xs={8} className={classes.label}>Total C 2024: </Grid><Grid item xs={4} className={classes.number}>{selectedWish.prevAnnualCPurchase}</Grid></>)}
                  <Grid item xs={8} className={classes.label}></Grid><Grid item xs={4} className={classes.text}></Grid>
                </Grid>
              </Grid>
            </Grid>
            
          </TableCell>
        </TableRow>
        <TableRow component="tr">
          <TableCell className={classes.cell} style={{ borderBottom: 'none' }}>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Grid container spacing={1} style={classes.grid}>
                  <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
                  {selectedWish.amountRTW && (<><Grid item xs={4} className={classes.label}>RTW: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountRTW}</Grid></>)}
                  {selectedWish.amountBW && (<><Grid item xs={4} className={classes.label}>BW: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountBW}</Grid></>)}
                  {selectedWish.amountMOE && (<><Grid item xs={4} className={classes.label}>MOE: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountMOE}</Grid></>)}
                  {selectedWish.amountAD && (<><Grid item xs={4} className={classes.label}>AD: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountAD}</Grid></>)}
                  <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1} style={classes.grid}>
                  <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
                  {selectedWish.amountA && (<><Grid item xs={4} className={classes.label}>A: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountA}</Grid></>)}
                  {selectedWish.amountZ && (<><Grid item xs={4} className={classes.label}>Z: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountZ}</Grid></>)}
                  {selectedWish.amountDOHA && (<><Grid item xs={4} className={classes.label}>DOHA: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountDOHA}</Grid></>)}
                  {selectedWish.amountBAH && (<><Grid item xs={4} className={classes.label}>BAH: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountBAH}</Grid></>)}
                  <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1} style={classes.grid}>
                  <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
                  {selectedWish.amountMP && (<><Grid item xs={4} className={classes.label}>MP: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountMP}</Grid></>)}
                  {selectedWish.amountFS && (<><Grid item xs={4} className={classes.label}>FS: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountFS}</Grid></>)}
                  {selectedWish.amountKWT && (<><Grid item xs={4} className={classes.label}>KWT: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountKWT}</Grid></>)}
                  {selectedWish.amountOther && (<><Grid item xs={4} className={classes.label}>Other: </Grid><Grid item xs={8} className={classes.number}>{selectedWish.amountOther}</Grid></>)}
                  <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
                </Grid>
              </Grid>
            </Grid>

          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
