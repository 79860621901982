import { format } from 'date-fns';

import { WishState } from './WishState';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  cell: {
    padding: theme.spacing(1),
  },
  label: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right'
  },
  stateLabel: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right',
    marginTop: theme.spacing(0.5)
  },
  text: {
    fontSize: '0.85em',
    fontWeight: 400,
  },
}));

export const WishClient = (props: any) => {
  const { selectedWish } = props;

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}><div className={classes.heading}>Client Details</div></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow component="tr">
          <TableCell className={classes.cell} style={{borderBottom: 'none'}}>
            <Grid container spacing={1} style={classes.grid}>
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.clientId && (<><Grid item xs={4} className={classes.label}>CEGID N<sup>o</sup>: </Grid><Grid item xs={8} className={classes.text} style={{ paddingTop: '11px' }}>{selectedWish.clientId}</Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.name && (<><Grid item xs={4} className={classes.label}>Name: </Grid><Grid item xs={8} className={classes.text}><b>{selectedWish.name}</b></Grid></>)}
              {selectedWish.email && (<><Grid item xs={4} className={classes.label}>Email: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.email}</Grid></>)}
              {selectedWish.phone && (<><Grid item xs={4} className={classes.label}>Phone: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.phone}</Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.advisorUserName && (<><Grid item xs={4} className={classes.label}>Advisor: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.advisorUserName}</Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.createdAt && (<><Grid item xs={4} className={classes.label}>Created: </Grid><Grid item xs={8} className={classes.text}>{format(new Date(selectedWish.createdAt), 'dd-MMM-yyyy hh:mm')}</Grid></>)}
              {selectedWish.updatedAt && (<><Grid item xs={4} className={classes.label}>Updated: </Grid><Grid item xs={8} className={classes.text}>{format(new Date(selectedWish.updatedAt), 'dd-MMM-yyyy hh:mm')}</Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.state && (<><Grid item xs={4} className={classes.stateLabel}>State: </Grid><Grid item xs={8} className={classes.text}><WishState state={selectedWish.state} /></Grid></>)}
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
