import { useState } from 'react';
import { deleteGroupUser } from "../../../services/users";

import { AppMessage } from '../../../shared/UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles()((theme) => ({
  content: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export const DeleteUserGroupModal = (props: any) => {
  const { selectedUser, selectedGroup } = props;
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
  };

  const handleSubmit = () => {
    console.log('handleSubmit');
    setLoading(true);
    setMessage({ type: 'warning', description: `Removing User: <strong>${selectedUser?.name}</strong> from Group: <strong>${selectedGroup?.name}</strong> ... ` });
    deleteGroupUser(selectedGroup?.groupId, selectedUser?.userId).then((data: any) => {
      console.log('Delete Group User', data);
      if (data) {
        setMessage({ type: 'success', description: `User: <strong>${selectedUser?.name}</strong> has been removed from Group: <strong>${selectedGroup?.name}</strong> <small>at ${new Date().toString()}</small>` });
        props.handleSubmit(selectedGroup?.groupId);
        clearErrors();
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Delete User Group</DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Are you sure you want to <strong>remove</strong> the user from this Group:</DialogContentText>
        <div className={classes.content}>
          <h3>{selectedGroup?.name}</h3>
          <h5>{selectedGroup?.groupId}</h5>
        </div>
        <AppMessage message={message} isLoading={isLoading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary" disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color="primary" variant="contained" disabled={isLoading} disableElevation>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};
