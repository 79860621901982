import { api } from "./api";

const request = api('api');

export const getWishes = async (groupId: string) => {
  return await request(`wish/${groupId}`);
};

export const getWish = async (groupId: string, wishId: string) => {
  return await request(`wish/${groupId}/${wishId}`);
};

export const createWish = async (groupId: string, wishType: string, clientId: string, name: string, email: string, phone: string, status: boolean) => {
  return await request(`wish/${groupId}`, 'post', undefined, { wishType, clientId, name, email, phone, status });
};

export const updateWish = async (groupId: string, wishId: string, clientId: string, name: string, email: string, phone: string, status: boolean) => {
  return await request(`wish/${groupId}/${wishId}`, 'put', undefined, { clientId, name, email, phone, status });
};

export const deleteWish = async (groupId: string, wishId: string) => {
  return await request(`wish/${groupId}/${wishId}`, 'delete');
};

export const advisorWish = async (groupId: string, wishId: string, userId: string, notes: string, highlights: string) => {
  return await request(`wish/${groupId}/${wishId}`, 'patch', {type: 'advisor'}, { userId, notes, highlights });
};

export const productWish = async (groupId: string, wishId: string, productId: string, approvedBy: string, bag: string, suprise: string ) => {
  return await request(`wish/${groupId}/${wishId}`, 'patch', {type: 'product'}, { productId, approvedBy, bag, suprise });
};

export const submitWish = async (groupId: string, wishId: string, isSubmitted: string, notes: string) => {
  return await request(`wish/${groupId}/${wishId}`, 'patch', {type: 'submit'}, { isSubmitted, notes });
};

export const reviewWish = async (groupId: string, wishId: string, cegidCreatedAt: string, totalPurchase: string, annualPurchase: string, annualCPurchase: string, prevAnnualPurchase: string, prevAnnualCPurchase: string, prevKBC1: string, prevKBC1PurchasedAt: string, prevKBC2: string, prevKBC2PurchasedAt: string, prevKBC3: string, prevKBC3PurchasedAt: string, prevKBC4: string, prevKBC4PurchasedAt: string, sinceKBCPurchase: string, amountRTW: string, amountA: string, amountMP: string, amountBW: string, amountZ: string, amountFS: string, amountMOE: string, amountDOHA: string, amountKWT: string, amountAD: string, amountBAH: string, amountOther: string, notes: string, state: string) => {
  return await request(`wish/${groupId}/${wishId}`, 'patch', {type: 'review'}, { cegidCreatedAt, totalPurchase, annualPurchase, annualCPurchase, prevAnnualPurchase, prevAnnualCPurchase, prevKBC1, prevKBC1PurchasedAt, prevKBC2, prevKBC2PurchasedAt, prevKBC3, prevKBC3PurchasedAt, prevKBC4, prevKBC4PurchasedAt, sinceKBCPurchase, amountRTW, amountA, amountMP, amountBW, amountZ, amountFS, amountMOE, amountDOHA, amountKWT, amountAD, amountBAH, amountOther, notes, state });
};

export const approveWish = async (groupId: string, wishId: string, isApproved: string, notes: string) => {
  return await request(`wish/${groupId}/${wishId}`, 'patch', {type: 'approve'}, { isApproved, notes });
};

export const scheduleWish = async (groupId: string, wishId: string, price: string, notes: string, scheduleDate: string, state: string) => {
  return await request(`wish/${groupId}/${wishId}`, 'patch', {type: 'schedule'}, { price, notes, scheduleDate, state });
};

export const deliverWish = async (groupId: string, wishId: string, isDelivered: string, notes: string, deliveredAt: string) => {
  return await request(`wish/${groupId}/${wishId}`, 'patch', {type: 'deliver'}, { isDelivered, notes, deliveredAt });
};

export const stateWish = async (groupId: string, wishId: string, state: string) => {
  return await request(`wish/${groupId}/${wishId}`, 'patch', {type: 'state'}, { state });
};

export const doCreateWish = async (groupId: string, wishType: string, clientId: string, name: string, email: string, phone: string, status: boolean, userId: string, notes: string, highlights: string, productId: string, approvedBy: string, bag: string, suprise: string) => {
  const result1 = await createWish(groupId, wishType, clientId, name, email, phone, status);
  const result2 = await advisorWish(groupId, result1.data.wishId, userId, notes, highlights);
  const result3 = await productWish(groupId, result1.data.wishId, productId, approvedBy, bag, suprise);
  console.log('doCreateWish', result1, result2, result3);
  return result1;
}

export const doUpdateWish = async (groupId: string, wishId: string, clientId: string, name: string, email: string, phone: string, status: boolean, userId: string, notes: string, highlights: string, productId: string, approvedBy: string, bag: string, suprise: string) => {
  const result1 = await updateWish(groupId, wishId, clientId, name, email, phone, status);
  const result2 = await advisorWish(groupId, wishId, userId, notes, highlights);
  const result3 = await productWish(groupId, wishId, productId, approvedBy, bag, suprise);
  console.log('doUpdateWish', result1, result2, result3);
  return result1;
}