import { api } from "./api";

const request = api('api');

export const listBudget = async (groupId: string, year: number) => {
  return await request(`budget/${groupId}/${year}`);
};

export const getBudget = async (groupId: string, year: number, month: number) => {
  return await request(`budget/${groupId}/${year}/${getMonth(month)}`);
};

export const createBudget = async (groupId: string, year: number, month: number, kelly: number, birkin: number, status: boolean) => {
  return await request(`budget/${groupId}`, 'post', undefined, { year, month: getMonth(month), kelly, birkin, status });
};

export const updateBudget = async (groupId: string, year: number, month: number, kelly: number, birkin: number, status: boolean) => {
  return await request(`budget/${groupId}/${year}/${getMonth(month)}`, 'put', undefined, { year, month: getMonth(month), kelly, birkin, status });
};

export const deleteBudget = async (groupId: string, year: number, month: number) => {
  return await request(`budget/${groupId}/${year}/${getMonth(month)}`, 'delete');
};

const getMonth = (month: number) => {
  return `${(month + 1) < 10 ? '0' : ''}${(month + 1)}`;
}