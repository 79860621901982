import { useState, useEffect, useMemo } from 'react';

import { updateGroupUser, getGroupUser } from '../../../services/users';
import { useApiGroupList } from '../../../hooks/admin/useApiGroupList';
import { useApiUserList } from '../../../hooks/admin/useApiUserList';

import { AppMessage } from '../../../shared/UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(0.5)
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export const EditGroupUserModal = (props: any) => {
  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useApiGroupList();
  const { data: dataUserList, isLoading: isLoadingUserList, mutate: mutateUserList } = useApiUserList();

  const [groupId, setGroupId] = useState('');
  const [userId, setUserId] = useState('');
  const [roles, setRoles] = useState('');
  const [status, setStatus] = useState(true);

  const [errorGroup, setErrorGroup] = useState('');
  const [errorUser, setErrorUser] = useState('');
  const [errorRoles, setErrorRoles] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const group = useMemo(() => dataGroupList?.find((x: any) => x.groupId === groupId), [dataGroupList, groupId]);
  const user = useMemo(() => dataUserList?.find((x: any) => x.userId === userId), [dataUserList, userId]);

  const isDisabled = useMemo(() => (isLoading || isLoadingGroupList || isLoadingUserList), [isLoading, isLoadingGroupList, isLoadingUserList]);

  useEffect(() => {
    if (groupId && userId) {
      setLoading(true);
      getGroupUser(groupId, userId)
        .then((result: any) => {
          console.log('getGroupUser', result);
          if (result.status) {
            setRoles(result.data.roles || '');
            setStatus(result.data.status || false);
          }
        }).catch((e: any) => {
          console.log(e);
          // if (e.message) {
          //   setMessage({ type: 'error', description: e.message });
          // } else {
          //   setMessage({ type: 'error', description: e });
          // }
        }).finally(() => setLoading(false));
    }
  }, [groupId, userId, dataUserList, dataGroupList])

  useEffect(() => {
    clearForm();
    clearErrors();
    setGroupId(props?.selectedGroup?.groupId || '');
    setUserId(props?.selectedUser?.userId || '');
  }, [props.selectedGroup, props.selectedUser])

  const clearForm = () => {
    setGroupId('');
    setUserId('');
    setRoles('');
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorGroup('');
    setErrorUser('');
    setErrorRoles('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (groupId.length === 0) {
      setErrorGroup('Select the group');
      return false;
    }
    if (userId.length === 0) {
      setErrorUser('Select the user');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Adding User: <strong>${user?.name}</strong> to Group: <strong>${group?.name}</strong> ... ` });
    updateGroupUser(groupId, userId, roles, status).then((data: any) => {
      console.log('updateGroupUser', data);
      if (data) {
        setMessage({ type: 'success', description: `User: <strong>${user?.name}</strong> added to Group: <strong>${group?.name}</strong> <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(groupId, userId);
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };


  const handleMutate = () => {
    mutateUserList();
    mutateGroupList();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <DialogTitle>User Group Membership</DialogTitle>
        </Grid>
        <Grid item xs={1}>
          {isDisabled && (
            <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
          )}
          {!isDisabled && (
            <Tooltip title="refresh"><IconButton color="primary" onClick={() => handleMutate()}><RefreshIcon /></IconButton></Tooltip>
          )}
        </Grid>
      </Grid>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Update the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <FormControl className={classes.formcontrol} fullWidth>
            <InputLabel id='groupId-label'>group</InputLabel>
            <Select
              labelId='groupId-label'
              id='groupId'
              label="group"
              value={groupId}
              onChange={(e: any) => setGroupId(e.target.value)}
              disabled={isDisabled}
              autoFocus
            >
              {dataGroupList?.map((group: any) => (
                <MenuItem key={group.groupId} value={group.groupId}>{group.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.errorText}>{errorGroup}</FormHelperText>
          </FormControl>

          <FormControl className={classes.formcontrol} fullWidth>
            <InputLabel id='userId-label'>user</InputLabel>
            <Select
              labelId='userId-label'
              id='userId'
              label="user"
              value={userId}
              onChange={(e: any) => setUserId(e.target.value)}
              disabled={isDisabled}
            >
              {dataUserList?.map((user: any) => (
                <MenuItem key={user.userId} value={user.userId}>{user.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.errorText}>{errorUser}</FormHelperText>
          </FormControl>

          <FormControl className={classes.formcontrol} fullWidth>
            <InputLabel id='role-label'>role</InputLabel>
            <Select
              labelId='role-label'
              id='roles'
              label="role"
              value={roles}
              onChange={(e: any) => setRoles(e.target.value)}
              disabled={isDisabled}
            >
              <MenuItem value='administrator'>Administrator</MenuItem>
              <MenuItem value='director'>Director</MenuItem>
              <MenuItem value='manager'>Manager</MenuItem>
              <MenuItem value='reviewer'>Reviewer</MenuItem>
              <MenuItem value='advisor'>Advisor</MenuItem>
            </Select>
            <FormHelperText className={classes.errorText}>{errorRoles}</FormHelperText>
          </FormControl>

          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup value={status} onChange={(_e: any, val: any) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; enabled </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
