import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  cell: {
    padding: theme.spacing(1),
  },
  label: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right'
  },
  stateLabel: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right',
    marginTop: theme.spacing(0.5)
  },
  text: {
    fontSize: '0.85em',
    fontWeight: 400,
  },
}));

export const WishNotes = (props: any) => {
  const { selectedWish } = props;

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}><div className={classes.heading}>Notes</div></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow component="tr">
          <TableCell className={classes.cell} style={{borderBottom: 'none'}}>
            <Grid container spacing={1} style={classes.grid}>
            <Grid item xs={2} className={classes.label}></Grid><Grid item xs={10} className={classes.text}></Grid>
              {selectedWish.advisorHighlights && (<><Grid item xs={2} className={classes.label}>Highlights: </Grid><Grid item xs={10} className={classes.text}>{selectedWish.advisorHighlights}</Grid></>)}
              {selectedWish.advisorHighlights && (<><Grid item xs={2} className={classes.label}></Grid><Grid item xs={10} className={classes.text}></Grid></>)}
              {selectedWish.advisorNotes && (<><Grid item xs={2} className={classes.label}>Insights: </Grid><Grid item xs={10} className={classes.text}>{selectedWish.advisorNotes}</Grid></>)}
              {selectedWish.advisorNotes && (<><Grid item xs={2} className={classes.label}></Grid><Grid item xs={10} className={classes.text}></Grid></>)}
              {selectedWish.submitNotes && (<><Grid item xs={2} className={classes.label}>Manager: </Grid><Grid item xs={10} className={classes.text}>{selectedWish.submitNotes}</Grid></>)}
              {selectedWish.submitNotes && (<><Grid item xs={2} className={classes.label}></Grid><Grid item xs={10} className={classes.text}></Grid></>)}
              {selectedWish.reviewNotes && (<><Grid item xs={2} className={classes.label}>Reviewer: </Grid><Grid item xs={10} className={classes.text}>{selectedWish.reviewNotes}</Grid></>)}
              {selectedWish.reviewNotes && (<><Grid item xs={2} className={classes.label}></Grid><Grid item xs={10} className={classes.text}></Grid></>)}
              {selectedWish.approveNotes && (<><Grid item xs={2} className={classes.label}>Approval: </Grid><Grid item xs={10} className={classes.text}>{selectedWish.approveNotes}</Grid></>)}
              {selectedWish.approveNotes && (<><Grid item xs={2} className={classes.label}></Grid><Grid item xs={10} className={classes.text}></Grid></>)}
              {selectedWish.scheduledAt && selectedWish.scheduleNotes && (<><Grid item xs={2} className={classes.label}>Schedule: </Grid><Grid item xs={10} className={classes.text}>{selectedWish.scheduleNotes}</Grid></>)}
              {selectedWish.scheduledAt && selectedWish.scheduleNotes && (<><Grid item xs={2} className={classes.label}></Grid><Grid item xs={10} className={classes.text}></Grid></>)}
              {selectedWish.deliveredAt && selectedWish.deliverNotes && (<><Grid item xs={2} className={classes.label}>Delivery: </Grid><Grid item xs={10} className={classes.text}>{selectedWish.deliverNotes}</Grid></>)}
              <Grid item xs={2} className={classes.label}></Grid><Grid item xs={10} className={classes.text}></Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
