import { useMemo } from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import LaunchIcon from '@mui/icons-material/Launch';
import BlockIcon from '@mui/icons-material/Block';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  finalRow: {
    backgroundColor: '#eee'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  number: {
    fontFamily: 'Roboto Mono',
    fontWeight: 400,
  }
}));

export const WishTable = (props: any) => {
  const { year, month, day, data, selectWish, selectedWish, handleSchedule, handleDeliver, handleCancel, handlePanel } = props;

  const total = useMemo(() => {
    var price = 0;
    if (data) {
      for (const wish of data) {
        price += Number(wish.price);
      }
    }
    return price;
  }, [data]);

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableBody>
          {data?.map((row: any) => (
            <TableRow component="tr" key={row.wishId} onClick={() => selectWish(row.wishId)} className={clsx(classes.row, { [classes.selected]: (selectedWish?.groupId === row.groupId && selectedWish?.wishId === row.wishId) })}>
              <TableCell className={classes.cell} align="center" width="180px">{row.clientId}</TableCell>
              <TableCell className={classes.cell} align="left" width="250px"><b>{row.name}</b></TableCell>
              <TableCell className={classes.cell} align="left" width="180px">{row.phone}</TableCell>
              <TableCell className={classes.cell} align="left">{row.productName} {row.bag}</TableCell>
              <TableCell className={classes.cell} align="right" width="120px"><span className={classes.number}>{Number(row.price).toLocaleString()}</span></TableCell>
              <TableCell className={classes.cell} align="center" width="180px">{row.advisorUserName}</TableCell>
              <TableCell className={classes.cell} align="right" width="200px">
                <Tooltip title="schedule"><IconButton color="primary" onClick={() => handleSchedule(row.wishId)}><CalendarMonthIcon /></IconButton></Tooltip>
                <Tooltip title="deliver"><IconButton color="primary" onClick={() => handleDeliver(row.wishId)}><DoneAllIcon /></IconButton></Tooltip>
                <Tooltip title="cancel"><IconButton color="primary" onClick={() => handleCancel(row.wishId)}><BlockIcon /></IconButton></Tooltip>
                <Tooltip title="view wish"><IconButton color="primary" onClick={() => handlePanel(row.wishId)}><LaunchIcon /></IconButton></Tooltip>
              </TableCell>
            </TableRow>
          ))}
          <TableRow component="tr" className={classes.finalRow}>
            <TableCell className={classes.cell} align="center" width="180px"></TableCell>
            <TableCell className={classes.cell} align="left" width="250px"><b>{format(new Date(year, month, day), 'PPPP')}</b></TableCell>
            <TableCell className={classes.cell} align="left" width="180px"></TableCell>
            <TableCell className={classes.cell} align="right"><b>Total: </b></TableCell>
            <TableCell className={classes.cell} align="right" width="120px"><b><span className={classes.number}>{total.toLocaleString()}</span></b></TableCell>
            <TableCell className={classes.cell} align="center" width="180px"></TableCell>
            <TableCell className={classes.cell} align="right" width="200px"></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
