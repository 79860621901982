import { useState } from 'react';
import { stateWish } from "../../services/wish";
import { EState } from '../../services/types';

import { WishTitle } from '../Wish/WishTitle';
import { AppMessage } from '../UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    fontWeight: 300,
    fontSize: '1em',
  },
  content: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export const CancelWishModal = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
  };

  const handleSubmit = () => {
    console.log('handleSubmit');
    setLoading(true);
    setMessage({ type: 'warning', description: `Canceling Wish: <strong>${props.selectedWish?.name}</strong> ... ` });
    stateWish(props.selectedGroup?.groupId, props.selectedWish?.wishId, EState.cancelled).then((data: any) => {
      console.log('Cancel Wish', data);
      if (data) {
        setMessage({ type: 'success', description: `Wish: <strong>${props.selectedWish?.wishId}</strong> has been cancelled <small>at ${new Date().toString()}</small>` });
        props.handleSubmit(props.selectedGroup?.groupId, props.selectedWish?.wishId);
        clearErrors();
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => handleCancel()} aria-labelledby="form-dialog-title">
      <Grid container justifyContent='space-between'>
        <Grid item>
          <DialogTitle>Cancel <WishTitle wishType={props.selectedWish?.wishType} /></DialogTitle>
        </Grid>
      </Grid>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Are you sure you want to <strong>cancel</strong> this Wish:</DialogContentText>
        <div className={classes.content}>
          <h3>{props.selectedWish?.name}</h3>
          <h5>{props.selectedWish?.productName} {props.selectedWish?.bag}</h5>
        </div>
        <AppMessage message={message} isLoading={isLoading} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="primary" disabled={isLoading}>close</Button>
        <Button onClick={() => handleSubmit()} color="primary" variant="contained" disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
