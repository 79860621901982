import { useState } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { EditProductModal } from './EditProductModal';
import { DeleteProductModal } from './DeleteProductModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
}));

export const ProductTable = (props: any) => {
  const { data, isLoading, mutate, selectProduct, selectedProduct } = props;
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openDeleteProductModal, setOpenDeleteProductModal] = useState(false);

  const handleCreateProduct = () => {
    console.log('handleCreateProduct');
    selectProduct();
    setOpenEditProductModal(true);
  }

  const handleOpenEditProductModal = (id: string) => {
    console.log('handleOpenEditProductModal', id);
    selectProduct(id);
    setOpenEditProductModal(true);
  };

  const submitEditProduct = (productId: string) => {
    console.log('submitEditProduct', productId);
    selectProduct();
    setOpenEditProductModal(false);
    mutate();
  };

  const closeEditProductModal = () => {
    console.log('closeEditProduct');
    selectProduct();
    setOpenEditProductModal(false);
  };

  const handleOpenDeleteProductModal = (id: string) => {
    console.log('handleOpenDeleteProductModal', id);
    selectProduct(id);
    setOpenDeleteProductModal(true);
  };

  const submitDeleteProduct = (productId: string) => {
    console.log('submitDeleteProduct', productId);
    selectProduct();
    setOpenDeleteProductModal(false);
    mutate();
  };

  const closeDeleteProductModal = () => {
    console.log('closeDeleteProduct');
    selectProduct();
    setOpenDeleteProductModal(false);
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Category</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Approved By</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>SKU</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Name</span></TableCell>
            <TableCell className={classes.cell} align="right"><span className={classes.heading}>Price</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Updated</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Status</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              <Tooltip title="create a new wish"><IconButton color="primary" onClick={() => handleCreateProduct()}><AddIcon /></IconButton></Tooltip>
              {isLoading && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoading && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any) => (
            <TableRow component="tr" key={row.productId} onClick={() => selectProduct(row.productId)} className={clsx(classes.row, { [classes.selected]: (selectedProduct?.productId === row.productId) })}>
              <TableCell className={classes.cell} align="left">{row.category}</TableCell>
              <TableCell className={classes.cell} align="left">{row.approvedBy}</TableCell>
              <TableCell className={classes.cell} align="center"><b>{row.sku}</b></TableCell>
              <TableCell className={classes.cell} align="left">{row.name}</TableCell>
              <TableCell className={classes.cell} align="right">{row.price}</TableCell>
              <TableCell className={classes.cell} align="center">{row.updatedAt ? format(new Date(row.updatedAt), 'dd-MMM-yyyy HH:mm') : ''}</TableCell>
              <TableCell className={classes.cell} align="center">{row.status ? 'active' : 'disabled'}</TableCell>
              <TableCell className={classes.cell} align="right">
                <Tooltip title="edit"><IconButton color="primary" onClick={() => handleOpenEditProductModal(row.productId)}><EditIcon /></IconButton></Tooltip>
                <Tooltip title="delete"><IconButton color="primary" onClick={() => handleOpenDeleteProductModal(row.productId)}><DeleteIcon /></IconButton></Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditProductModal selectedProduct={selectedProduct} open={openEditProductModal} closeModal={closeEditProductModal} handleSubmit={submitEditProduct} />
      <DeleteProductModal selectedProduct={selectedProduct} open={openDeleteProductModal} closeModal={closeDeleteProductModal} handleSubmit={submitDeleteProduct} />
    </>
  );
};
