import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useApiGroupUserList } from '../../hooks/admin/useApiGroupUserList';
import { useApiGroupList } from '../../hooks/admin/useApiGroupList';
import { useApiUserList } from '../../hooks/admin/useApiUserList';
import { useAuthStore } from '../../hooks/context/useAuthStore';

import { AppSkeleton } from "../../shared/UI/AppSkeleton";
import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { GroupTable } from './components/GroupTable';
import { UserTable } from './components/UserTable';
import { CreateUserModal } from './components/CreateUserModal';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1, 3, 3, 3),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
    color: '#333042',
  },
  subtitle: {
    marginBlockStart: 0,
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },  
}));

export const Users = () => {

  const { isAuthorized } = useAuthStore();
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const [selectedUser, setSelectedUser] = useState<any>();
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);

  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useApiGroupList();
  const { data: dataUserList, isLoading: isLoadingUserList, mutate: mutateUserList } = useApiUserList();
  const { data: dataGroupUsers, isLoading: isLoadingGroupUsers, mutate: mutateGroupUsers } = useApiGroupUserList();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const selectGroup = (id?: string) => {
    const group = dataGroupList?.find((x: any) => x.groupId === id);
    setSelectedGroup(group);
    setSelectedUser(undefined);
  }

  const selectUser = (id?: string) => {
    console.log('selectUser', id);
    const user = dataUserList?.find((x: any) => x.userId === id);
    setSelectedUser(user);
  }

  const mutate = () => {
    mutateGroupList();
    mutateUserList();
    mutateGroupUsers();
  }

  const handleOpenCreateUserModal = () => {
    console.log('handleOpenCreateUserModal');
    setOpenCreateUserModal(true);
  };

  const submitCreateUser = (id: string) => {
    console.log('submitCreateUser', id);
    setOpenCreateUserModal(false);
    mutate();
    navigate(`/user/${id}`);
  };

  const closeCreateUserModal = () => {
    console.log('closeCreateUser');
    setOpenCreateUserModal(false);
  };

  const isLoading = useMemo(() => (isLoadingGroupUsers|| isLoadingUserList), [isLoadingGroupUsers, isLoadingUserList]);

  const users = useMemo(() => {
    if (!selectedGroup) return dataUserList;
    const userGroups = dataGroupUsers?.filter((x: any) => x.groupId === selectedGroup.groupId);
    return dataUserList?.filter((x: any) => userGroups?.find((y: any) => y.userId === x.userId))
  }, [selectedGroup, dataGroupUsers, dataUserList]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Client Manager</h1>
        <h4 className={classes.subtitle}><span>Add</span> &amp; <span>Remove</span> Users Profiles</h4>
      </div>
      {(!Array.isArray(dataGroupList)) && <AppSkeleton className={classes.skeleton} />}
      {(Array.isArray(dataGroupList)) && (
        <Paper className={classes.paper}>
          <GroupTable data={dataGroupList} isLoading={isLoadingGroupList || isLoadingGroupUsers} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} handleCreateUser={handleOpenCreateUserModal}  />
        </Paper>
      )}

      {(!Array.isArray(users)) && <AppSkeleton className={classes.skeleton} style={{ height: '500px' }} />}
      {(Array.isArray(users)) && (
        <Paper className={classes.paper}>
          <UserTable data={users} isLoading={isLoading} mutate={mutate} selectUser={selectUser} selectedUser={selectedUser} selectedGroup={selectedGroup} handleCreateUser={handleOpenCreateUserModal} />
        </Paper>
      )}
      <CreateUserModal selectedGroup={selectedGroup} open={openCreateUserModal} closeModal={closeCreateUserModal} handleSubmit={submitCreateUser} />
    </AppLayout>
  );
};
