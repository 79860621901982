import { format } from 'date-fns';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  cell: {
    padding: theme.spacing(1, 2),
  },
  label: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right'
  },
  stateLabel: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right',
    marginTop: theme.spacing(0.5)
  },
  text: {
    fontSize: '0.85em',
    fontWeight: 400,
  },
  textLrg: {
    fontSize: '1.2em',
    fontWeight: 700,
  },
  number: {
    fontSize: '0.85em',
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
  },
}));

export const WishPrevProduct = (props: any) => {
  const { selectedWish } = props;

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}><div className={classes.heading}>Previous Products</div></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow component="tr">
          <TableCell className={classes.cell} style={{ borderBottom: 'none' }}>
            <Grid container spacing={1} alignItems="center" style={classes.grid}>
              <Grid item xs={3} className={classes.label}></Grid><Grid item xs={9} className={classes.text}></Grid>
              {selectedWish.prevKBC1 && selectedWish.prevKBC1PurchasedAt && (<><Grid item xs={5} className={classes.label}>{format(new Date(selectedWish.prevKBC1PurchasedAt), 'dd-MMM-yyyy')}: </Grid><Grid item xs={7} className={classes.text}>{selectedWish.prevKBC1}</Grid></>)}
              {selectedWish.prevKBC2 && selectedWish.prevKBC2PurchasedAt && (<><Grid item xs={5} className={classes.label}>{format(new Date(selectedWish.prevKBC2PurchasedAt), 'dd-MMM-yyyy')}: </Grid><Grid item xs={7} className={classes.text}>{selectedWish.prevKBC2}</Grid></>)}
              {selectedWish.prevKBC3 && selectedWish.prevKBC3PurchasedAt && (<><Grid item xs={5} className={classes.label}>{format(new Date(selectedWish.prevKBC3PurchasedAt), 'dd-MMM-yyyy')}: </Grid><Grid item xs={7} className={classes.text}>{selectedWish.prevKBC3}</Grid></>)}
              {selectedWish.prevKBC4 && selectedWish.prevKBC4PurchasedAt && (<><Grid item xs={5} className={classes.label}>{format(new Date(selectedWish.prevKBC4PurchasedAt), 'dd-MMM-yyyy')}: </Grid><Grid item xs={7} className={classes.text}>{selectedWish.prevKBC4}</Grid></>)}
              <Grid item xs={3} className={classes.label}></Grid><Grid item xs={9} className={classes.text}></Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
