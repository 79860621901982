import { useState, useMemo } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { EState, EWishType } from '../../../services/types';
import { WishState } from '../../../shared/Wish/WishState';
import { useApiGroupUserList } from '../../../hooks/admin/useApiGroupUserList';
import { useApiUserList } from '../../../hooks/admin/useApiUserList';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
}));

export const WishTable = (props: any) => {
  const { data, isLoading, mutate, selectWish, selectedWish, selectedGroup, filterClientId, filterName, filterPhone, filterWishType, filterAdvisorUserId, filterState, filterScheduleDate, handlePanel, handleWish } = props;
  const { data: dataGroupUserList, isLoading: isLoadingGroupUserList, mutate: mutateGroupUserList } = useApiGroupUserList();
  const { data: dataUserList, isLoading: isLoadingUserList, mutate: mutateUserList } = useApiUserList();

  const [clientId, setClientId] = useState(filterClientId || '');
  const [name, setName] = useState(filterName || '');
  const [phone, setPhone] = useState(filterPhone || '');
  const [wishType, setWishType] = useState(filterWishType || '');
  const [userId, setUserId] = useState(filterAdvisorUserId || '');
  const [state, setState] = useState(filterState || '');
  const [scheduleDate, setScheduleDate] = useState(filterScheduleDate || '');

  const users = useMemo(() => dataUserList?.filter((user: any) => dataGroupUserList?.some((groupuser: any) => groupuser.groupId === selectedGroup?.groupId && groupuser.userId === user.userId)), [selectedGroup, dataGroupUserList, dataUserList]);

  const handleSubmit = () => { };
  const handleMutate = () => {
    mutate();
    mutateGroupUserList();
    mutateUserList();
  };
  const isLoadingData = (isLoading || isLoadingGroupUserList || isLoadingUserList);

  const wishes = useMemo(() => {
    if (!selectedGroup) return data;
    return data?.filter((x: any) =>
      (x.groupId === selectedGroup.groupId) &&
      (!clientId || x.clientId?.includes(clientId)) &&
      (!name || x.name?.includes(name)) &&
      (!phone || x.phone?.includes(phone)) &&
      (!wishType || wishType === x.wishType) &&
      (!userId || userId === x.advisorUserId) &&
      (!state || state === x.state) &&
      (!scheduleDate || (x.scheduleDate && format(new Date(x.scheduleDate), 'dd-MMM-yyyy').includes(scheduleDate)))
    );
  }, [selectedGroup, data, clientId, name, phone, wishType, userId, state, scheduleDate]);

  const { classes } = useStyles();
  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow component="tr" className={classes.row}>
            <TableCell className={classes.cell}>
              <TextField
                id='clientId'
                label='CEGID No'
                value={clientId}
                onChange={(e: any) => setClientId(e.target.value)}
                size='small'
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <TextField
                id='name'
                label='client name'
                value={name}
                onChange={(e: any) => setName(e.target.value)}
                size='small'
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <TextField
                id='phone'
                label='phone'
                value={phone}
                onChange={(e: any) => setPhone(e.target.value)}
                size='small'
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </TableCell>
            <TableCell className={classes.cell} style={{minWidth: '120px'}}>
              <FormControl className={classes.formcontrol} fullWidth>
                <InputLabel id='wishType-label'>type</InputLabel>
                <Select
                  labelId='wishType-label'
                  id='wishType'
                  label="type"
                  value={wishType}
                  onChange={(e: any) => setWishType(e.target.value)}
                  size='small'
                >
                  <MenuItem value=''>&nbsp;</MenuItem>
                  <MenuItem value={EWishType.wish}>Wish</MenuItem>
                  <MenuItem value={EWishType.appointment}>Appointment</MenuItem>
                </Select>
              </FormControl>
            </TableCell>            
            <TableCell className={classes.cell} style={{minWidth: '120px'}}>
              <FormControl className={classes.formcontrol} fullWidth>
                <InputLabel id='userId-label'>advisor</InputLabel>
                <Select
                  labelId='userId-label'
                  id='userId'
                  label="advisor"
                  value={userId}
                  onChange={(e: any) => setUserId(e.target.value)}
                  size='small'
                >
                  <MenuItem value=''>&nbsp;</MenuItem>
                  {users?.map((user: any) => (
                    <MenuItem key={user.userId} value={user.userId}>{user.username}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={classes.cell} style={{minWidth: '120px'}}>
              <FormControl className={classes.formcontrol} fullWidth>
                <InputLabel id='state-label'>state</InputLabel>
                <Select
                  labelId='state-label'
                  id='state'
                  label="state"
                  value={state}
                  onChange={(e: any) => setState(e.target.value)}
                  size='small'
                >
                  <MenuItem value=''>&nbsp;</MenuItem>
                  <MenuItem value={EState.created}>{EState.created}</MenuItem>
                  <MenuItem value={EState.submitted}>{EState.submitted}</MenuItem>
                  <MenuItem value={EState.rejected}>{EState.rejected}</MenuItem>
                  <MenuItem value={EState.reviewed}>{EState.reviewed}</MenuItem>
                  <MenuItem value={EState.approved}>{EState.approved}</MenuItem>
                  <MenuItem value={EState.denied}>{EState.denied}</MenuItem>
                  <MenuItem value={EState.scheduled}>{EState.scheduled}</MenuItem>
                  <MenuItem value={EState.completed}>{EState.completed}</MenuItem>
                  <MenuItem value={EState.cancelled}>{EState.cancelled}</MenuItem>
                </Select>
              </FormControl>
            </TableCell>
            <TableCell className={classes.cell}>
              <TextField
                id='scheduleDate'
                label='scheduled At'
                value={scheduleDate}
                onChange={(e: any) => setScheduleDate(e.target.value)}
                size='small'
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </TableCell>
            <TableCell className={classes.cell} style={{minWidth: '116px'}} align="right">
              {isLoadingData && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoadingData && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => handleMutate()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {wishes?.sort((a: any, b: any) => a.scheduleDate?.localeCompare(b.scheduleDate)).map((row: any) => (
            <TableRow component="tr" key={row.wishId} onClick={() => selectWish(row.wishId)} className={clsx(classes.row, { [classes.selected]: (selectedWish?.groupId === row.groupId && selectedWish?.wishId === row.wishId) })}>
              <TableCell className={classes.cell} align="center">{row.clientId}</TableCell>
              <TableCell className={classes.cell} align="left"><b>{row.name}</b></TableCell>
              <TableCell className={classes.cell} align="left">{row.phone}</TableCell>
              {/* <TableCell className={classes.cell} align="left">{row.bag}</TableCell> */}
              <TableCell className={classes.cell} align="center">{row.wishType}</TableCell>
              <TableCell className={classes.cell} align="center">{row.advisorUserName}</TableCell>
              <TableCell className={classes.cell} align="center"><WishState state={row.state} /></TableCell>
              <TableCell className={classes.cell} align="center">{row.scheduleDate ? format(new Date(row.scheduleDate), 'dd-MMM-yyyy') : ''}</TableCell>
              <TableCell className={classes.cell} align="right">
                <Tooltip title="view wish"><IconButton color="primary" onClick={() => handlePanel(row.wishId)}><LaunchIcon /></IconButton></Tooltip>
                <Tooltip title="go to wish"><IconButton color="primary" onClick={() => handleWish(row.wishId)}><ArrowRightIcon /></IconButton></Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </form>
  );
};
