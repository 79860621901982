import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    fontFamily: "'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },  
  palette: {
    primary: {
      light: "#f58e4e",
      main: "rgb(199, 89, 40)",
      dark: "#c75928",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(26, 48, 63, 0.2)",
      main: "#1a303f",
      dark: "rgba(26, 48, 63, 0.6)",
      contrastText: "#fff",
    },
    background: {
      default: "#e3d9d0",
    }
  },
  
});
