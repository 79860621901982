import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { useAuthStore } from '../../hooks/context/useAuthStore';
import { useWishStore } from '../../hooks/context/useWishStore';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { WishTitle } from '../../shared/Wish/WishTitle';
import { WishPanels } from '../../shared/Wish/WishPanels';
import { WishButtons } from '../../shared/Wish/WishButtons';
import { WishActionButton } from '../../shared/Wish/WishActionButton';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  paper: {
    padding: theme.spacing(1, 2, 1, 2),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button: {
    marginRight: theme.spacing(1),
  }
}));

export const Wish = () => {
  const { groupId = '', wishId = '' } = useParams();
  const { selectedGroup, selectGroup, selectedWish, selectWish, isLoading, mutate, handleEdit, handleDelete, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handlePanel } = useWishStore();

  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  useEffect(() => {
    if (groupId) selectGroup(groupId);
  }, [selectGroup, groupId]);

  useEffect(() => {
    if (wishId) selectWish(wishId);
  }, [selectWish, wishId]);

  const handleBackToOverview = () => {
    navigate('/overview');
  }

  const handleBackToSearch = () => {
    navigate('/wish');
  }

  const { classes } = useStyles();
  return (
    <AppLayout>

      <Grid container justifyContent="space-between">
        <Grid item>
          <div className={classes.root}>
            <h1 className={classes.title}><WishTitle wishType={selectedWish?.wishType} /></h1>
            <h4 className={classes.subtitle}>{selectedWish?.name} - <span>{selectedWish?.productName}</span> {selectedWish?.bag}</h4>
          </div>
        </Grid>

      </Grid>
      {selectedWish?.wishId && (
        <>
          <Paper className={classes.paper}>
            <Grid container justifyContent='space-between'>
              <Grid item>
                <Tooltip title="back to overview"><IconButton color="primary" onClick={() => handleBackToOverview()}><DoubleArrowLeftIcon /></IconButton></Tooltip>
                <Tooltip title="back to search"><IconButton color="primary" onClick={() => handleBackToSearch()}><ArrowLeftIcon /></IconButton></Tooltip>
                <WishButtons
                  selectedWish={selectedWish}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleSubmit={handleSubmit}
                  handleReview={handleReview}
                  handleApprove={handleApprove}
                  handleSchedule={handleSchedule}
                  handleDeliver={handleDeliver}
                  handleCancel={handleCancel}
                  handleState={handleState}
                  handlePanel={handlePanel}
                />
                {isLoading && (
                  <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
                )}
                {!isLoading && (
                  <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
                )}
              </Grid>
              <Grid item>
                <WishActionButton
                  selectedWish={selectedWish}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  handleSubmit={handleSubmit}
                  handleReview={handleReview}
                  handleApprove={handleApprove}
                  handleSchedule={handleSchedule}
                  handleDeliver={handleDeliver}
                  handleCancel={handleCancel}
                  handleState={handleState}
                  handlePanel={handlePanel}
                />
              </Grid>
            </Grid>
          </Paper>
          <WishPanels
            selectedWish={selectedWish}
            selectedGroup={selectedGroup}
          />
        </>
      )}
    </AppLayout >
  );
};
