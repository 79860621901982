import { useEffect } from "react";

import { useQuery } from "../../hooks/utils/useQuery";
import { useAuthStore } from "../../hooks/context/useAuthStore";
import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
}));
export const Logout = () => {
  const { logout } = useAuthStore();
  const query = useQuery();

  useEffect(() => {
    var redirectTo = query.get('redirectTo') || '';
    const error = query.get('error') || '';
    if (redirectTo.indexOf('logout') >= 0) redirectTo = '';
    logout(redirectTo, error);
  }, []);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
      </div>
    </AppLayout>
  );
};
