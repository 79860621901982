import { useEffect } from 'react';

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useNavigate } from "react-router-dom";

import { AppLayout } from '../../shared/AppLayout/AppLayout';

import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
}));

export const Activity = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Activity</h1>
        <h4 className={classes.subtitle}><span>View</span> Reports &amp; Activity</h4>
      </div>
    </AppLayout>
  );
};
