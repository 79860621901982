import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../hooks/context/useAuthStore';
import { useApiProductList } from '../../hooks/api/useApiProductList';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { ProductTable } from './components/ProductTable';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  paper: {
    padding: theme.spacing(1, 3, 3, 3),
    marginBottom: theme.spacing(3),
  },
}));

export const Products = () => {
  const { isAuthorized } = useAuthStore();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const { data, isLoading, mutate } = useApiProductList();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const selectProduct = (id?: string) => {
    console.log('selectProduct', id);
    const product = data?.find((x: any) => x.productId === id);
    setSelectedProduct(product);
  }

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
        <h1 className={classes.title}>Products</h1>
        <h4 className={classes.subtitle}><span>Add</span> &amp; <span>Update</span> the Leather Products</h4>
      </div>
      <Paper className={classes.paper}>
        <ProductTable data={data} isLoading={isLoading} mutate={mutate} selectProduct={selectProduct} selectedProduct={selectedProduct} />
      </Paper>
    </AppLayout>
  );
};
