import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { NotifyProvider } from './context/NotifyProvider';

import { Home } from "./pages/Home/Home";
import { SignIn } from "./pages/SignIn/SignIn";
import { Overview } from "./pages/Overview/Overview";
import { Wishes } from "./pages/Wishes/Wishes";
import { Wish } from "./pages/Wish/Wish";
import { Schedule } from "./pages/Schedule/Schedule";
import { Planning } from "./pages/Planning/Planning";
import { Activity } from "./pages/Activity/Activity";
import { Products } from "./pages/Products/Products";
import { Users } from "./pages/Users/Users";
import { User } from "./pages/User/User";
import { Settings } from "./pages/Settings/Settings";
import { Logout } from "./pages/Logout/Logout";

export const Router = () => {
  return (
    <BrowserRouter>
      <NotifyProvider>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/signin"} element={<SignIn />} />
          <Route path={"/overview"} element={<Overview />} />
          <Route path={"/wish"} element={<Wishes />} />
          <Route path={"/wish/:groupId/:wishId"} element={<Wish />} />
          <Route path={"/schedule"} element={<Schedule />} />
          <Route path={"/planning"} element={<Planning />} />
          <Route path={"/activity"} element={<Activity />} />
          <Route path={"/products"} element={<Products />} />
          <Route path={"/user"} element={<Users />} />
          <Route path={"/user/:userId"} element={<User />} />
          <Route path={"/settings"} element={<Settings />} />
          <Route path={"/logout"} element={<Logout />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </NotifyProvider>
    </BrowserRouter>
  );
};
