import { api } from "./api";

const request = api('api');

export const getProducts = async () => {
  return await request(`product`);
};

export const getProduct = async (productId: string) => {
  return await request(`product/${productId}`);
};

export const createProduct = async (category: string, approvedBy: string, sku: string, name: string, price: string, status: boolean) => {
  return await request(`product`, 'post', undefined, { category, approvedBy, sku, name, price, status });
};

export const updateProduct = async (productId: string, category: string, approvedBy: string, sku: string, name: string, price: string, status: boolean) => {
  return await request(`product/${productId}`, 'put', undefined, { category, approvedBy, sku, name, price, status });
};

export const deleteProduct = async (category: string, productId: string) => {
  return await request(`product/${productId}`, 'delete');
};
