import clsx from 'clsx';

import { EState, EBool } from '../../services/types';

import { WishClient } from './WishClient';
import { WishActivity } from './WishActivity';
import { WishReview } from './WishReview';
import { WishPrevProduct } from './WishPrevProduct';
import { WishProduct } from './WishProduct';
import { WishNotes } from './WishNotes';

import Approved from '../../assets/background/approved.png';
import Denied from '../../assets/background/denied.png';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';

const useStyles: any = makeStyles()((theme) => ({
  grid: {
    width: '100%',
  },
  approved: {
    backgroundImage: `url('${Approved}')`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '150px 150px',
  },
  denied: {
    backgroundImage: `url('${Denied}')`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '150px 150px',
  }
}));

export const WishPanel = (props: any) => {
  const { selectedWish } = props;

  const { classes } = useStyles();
  return (
    <>
      <Grid container spacing={1} className={classes.grid}>
        <Grid item xs={3}>
          <WishClient selectedWish={selectedWish} />
        </Grid>
        <Grid item xs={5}>
          <WishReview selectedWish={selectedWish} />
        </Grid>
        <Grid item xs={4}>
          <WishActivity selectedWish={selectedWish} />
        </Grid>
      </Grid>

      <Grid container spacing={1} className={clsx(classes.grid, { [classes.denied]: (selectedWish?.state === EState.denied), [classes.approved]: (selectedWish?.isApproved === EBool.true)})}>
        <Grid item xs={3}>
          <WishProduct selectedWish={selectedWish} />
        </Grid>
        <Grid item xs={3}>
          <WishPrevProduct selectedWish={selectedWish} />
        </Grid>
        <Grid item xs={6}>
          <WishNotes selectedWish={selectedWish} />
        </Grid>
      </Grid>
    </>
  );
};
