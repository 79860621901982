import useSWR from 'swr';

import {useAuthStore} from "../context/useAuthStore";
import { getUserGroups } from "../../services/users";


const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

const apiUserGroups = ([_, userId]: any[]) => getUserGroups(userId);

export const useApiUserGroups = (userId: string) => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized && userId ? ['useApiUserGroups', userId] : null, apiUserGroups, options)
  return {
    data: data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};

