import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import { Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  month: {
    fontSize: '0.8em',
    textAlign: 'center',
    cursor: 'pointer'
  },  
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.info.contrastText,
    fontWeight: 700,
    padding: theme.spacing(1, 0),
    borderRadius: theme.spacing(2),
  },
  unselected: {
    cursor: 'pointer',
    fontWeight: 500,
    padding: theme.spacing(1, 0)
  }    
}));

const months = [
  { id: 0, name: 'January'},
  { id: 1, name: 'February'},
  { id: 2, name: 'March'},
  { id: 3, name: 'April'},
  { id: 4, name: 'May'},
  { id: 5, name: 'June'},
  { id: 6, name: 'July'},
  { id: 7, name: 'August'},
  { id: 8, name: 'September'},
  { id: 9, name: 'October'},
  { id: 10, name: 'November'},
  { id: 11, name: 'December'},
];

export const Months = (props: any) => {
  const { month, setMonth } = props;

  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        {months.map((m: any) => (
          <Grid item xs={4} className={classes.month} key={m.id} onClick={()=>setMonth(m.id)}>
            {m.id === month && (<div className={classes.selected}>{m.name}</div>)}
            {m.id !== month && (<div className={classes.unselected}>{m.name}</div>)}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};