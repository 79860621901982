import { useMemo } from 'react';

import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles()((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  table: {
    width: '100%',
  },
  cell: {
    fontSize: '0.75em',
    textAlign: 'center',
    margin: 0,
    padding: theme.spacing(0.5, 0),
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  selected: {
    background: theme.palette.primary.main,
    color: theme.palette.info.contrastText,
    padding: theme.spacing(1),
    fontWeight: 700,
    borderRadius: theme.spacing(2),
  },
  unselected: {
    cursor: 'pointer',
    padding: theme.spacing(1),
    fontWeight: 500,
  },
  button: {
    marginTop: theme.spacing(1)
  }

}));

export const Days = (props: any) => {
  const { year, month, day, setDay } = props;

  const rows = useMemo(() => {
    const numDays = new Date(year, month+1, 0).getDate();
    const thisMonth = new Date(year, month, 1);
    const weekday = thisMonth.getDay();
    const weeks = [] as any[];
    var days = [] as string[];

    for (var j = 0; j < weekday; j++) {
      days.push('');
    }
    for (var i = 1; i <= numDays; i++) {
      if (days.length >= 7) {
        weeks.push(days);
        days = [] as string[];
      }
      days.push(`${i}`);
    }
    if (days.length > 0 && days.length < 7) {
      for (var y = days.length; y < 7; y++) {
        days.push('');
      }
    }
    weeks.push(days);
    return weeks;
  }, [year, month]);

  const { classes } = useStyles();
  return (
    <Paper className={classes.paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}><span className={classes.heading}>S</span></TableCell>
            <TableCell className={classes.cell}><span className={classes.heading}>M</span></TableCell>
            <TableCell className={classes.cell}><span className={classes.heading}>T</span></TableCell>
            <TableCell className={classes.cell}><span className={classes.heading}>W</span></TableCell>
            <TableCell className={classes.cell}><span className={classes.heading}>T</span></TableCell>
            <TableCell className={classes.cell}><span className={classes.heading}>F</span></TableCell>
            <TableCell className={classes.cell}><span className={classes.heading}>S</span></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row: any, index: number) => (
            <TableRow component="tr" key={index}>
              {row.map((dd: string, index: number) => (
                <TableCell key={index} className={classes.cell} onClick={() => setDay(dd)}>
                  {dd === day.toString() && (<div className={classes.selected}>{dd}</div>)}
                  {dd !== day.toString() && (<div className={classes.unselected}>{dd}</div>)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container spacing="1" justifyContent="flex-end">
        <Grid item>
          <Button size="small" variant="outlined" onClick={() => setDay(0)} className={classes.button}>all</Button>
        </Grid>
      </Grid>
    </Paper>
  );
};