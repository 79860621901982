import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './AuthProvider';
import { WishProvider } from './WishProvider';

export const ContextProvider = (props: any) => {
  const { children } = props;
  return (
    <SnackbarProvider>
      <AuthProvider>
        <WishProvider>
          {children}
        </WishProvider>
      </AuthProvider>
    </SnackbarProvider>
  );
};
