import React, { createContext, useEffect, useState } from 'react';

import { useApiMyGroupList } from '../hooks/auth/useApiMyGroupList';
import { useApiWishList } from '../hooks/api/useApiWishList';

import { EditWishModal } from '../shared/Modal/EditWishModal';
import { DeleteWishModal } from '../shared/Modal/DeleteWishModal';
import { SubmitWishModal } from '../shared/Modal/SubmitWishModal';
import { ReviewWishModal } from '../shared/Modal/ReviewWishModal';
import { ApproveWishModal } from '../shared/Modal/ApproveWishModal';
import { ScheduleWishModal } from '../shared/Modal/ScheduleWishModal';
import { DeliverWishModal } from '../shared/Modal/DeliverWishModal';
import { CancelWishModal } from '../shared/Modal/CancelWishModal';
import { StateWishModal } from '../shared/Modal/StateWishModal';
import { PanelWishModal } from '../shared/Modal/PanelWishModal';

interface IWishContext {
  dataGroupList: any[];
  dataWishList: any[];
  isLoading: boolean;
  mutate: () => void;
  selectedGroup: any;
  selectGroup: (groupId: string) => void;
  selectedWish: any;
  selectWish: (wishId: string) => void;
  handleCreate: (type: string) => void;
  handleEdit: (wishId: string) => void;
  handleDelete: (wishId: string) => void;
  handleSubmit: (wishId: string) => void;
  handleReview: (wishId: string) => void;
  handleApprove: (wishId: string) => void;
  handleSchedule: (wishId: string) => void;
  handleDeliver: (wishId: string) => void;
  handleCancel: (wishId: string) => void;  
  handleState: (wishId: string) => void;
  handlePanel: (wishId: string) => void;
  handleWish: (wishId: string) => void;
};

const initialState: IWishContext = {
  dataGroupList: [],
  dataWishList: [],
  isLoading: false,
  mutate: () => { },
  selectedGroup: undefined,
  selectGroup: () => { },
  selectedWish: undefined,
  selectWish: () => { },
  handleCreate: () => { },
  handleEdit: () => { },
  handleDelete: () => { },
  handleSubmit: () => { },
  handleReview: () => { },
  handleApprove: () => { },
  handleSchedule: () => { },
  handleDeliver: () => { },
  handleState: () => { },
  handleCancel: () => { },
  handlePanel: () => { },
  handleWish: () => { },
};

export const WishContext = createContext<IWishContext>(initialState);

export const WishProvider = ({ children }: { children: React.ReactElement }) => {
  const [selectedGroup, setSelectedGroup] = useState<any>();
  const [selectedWish, setSelectedWish] = useState<any>();
  const [newWishType, setNewWishType] = useState('');
  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useApiMyGroupList();
  const { data: dataWishList, isLoading: isLoadingWishList, mutate: mutateWishList } = useApiWishList(selectedGroup?.groupId);

  const isLoading = isLoadingGroupList || isLoadingWishList;

  const selectGroup = (id?: string) => {
    const group = dataGroupList?.find((x: any) => x.groupId === id);
    localStorage.setItem('groupId', group?.groupId || '');
    setSelectedGroup(group);
    setSelectedWish(undefined);
  }

  const selectWish = (id?: string) => {
    console.log('selectWish', id);
    const wish = dataWishList?.find((x: any) => x.wishId === id);
    localStorage.setItem('wishId', wish?.wishId || '');
    setSelectedWish(wish || {});
  }

  const mutate = () => {
    mutateGroupList();
    mutateWishList();
  }

  useEffect(() => {
    if (!selectedGroup && dataGroupList?.length > 0) {
      var groupId = localStorage.getItem('groupId');
      if (!groupId || !dataGroupList?.find((x: any) => x.groupId === groupId)) {
        groupId = dataGroupList[0].groupId
      }
      if (groupId) {
        localStorage.setItem('groupId', groupId);
        setSelectedGroup(dataGroupList?.find((x: any) => x.groupId === groupId));
        setSelectedWish(undefined);
      }
    }
  }, [dataGroupList, selectedGroup]);


  useEffect(() => {
    if (selectedWish === undefined && dataWishList?.length > 0) {
      var wishId = localStorage.getItem('wishId');
      if (!wishId || !dataWishList?.find((x: any) => x.wishId === wishId)) {
        wishId = dataWishList[0].wishId
      }
      if (wishId) {
        localStorage.setItem('wishId', wishId);
        setSelectedWish(dataWishList?.find((x: any) => x.wishId === wishId));
      }
    }
  }, [dataWishList, selectedWish]);

  const [openEditWishModal, setOpenEditWishModal] = useState(false);
  const [openDeleteWishModal, setOpenDeleteWishModal] = useState(false);
  const [openSubmitWishModal, setOpenSubmitWishModal] = useState(false);
  const [openReviewWishModal, setOpenReviewWishModal] = useState(false);
  const [openApproveWishModal, setOpenApproveWishModal] = useState(false);
  const [openScheduleWishModal, setOpenScheduleWishModal] = useState(false);
  const [openDeliverWishModal, setOpenDeliverWishModal] = useState(false);
  const [openStateWishModal, setOpenStateWishModal] = useState(false);
  const [openCancelWishModal, setOpenCancelWishModal] = useState(false);
  const [openPanelWishModal, setOpenPanelWishModal] = useState(false);


  const handleCreateWish = (type: string) => {
    console.log('handleCreateWish');
    selectWish();
    setNewWishType(type);
    setOpenEditWishModal(true);
  }

  const handleWish = (id: string) => {
    console.log('handleWish', id);
    selectWish(id);
    if (selectedWish) window.location.href=`/wish/${selectedWish.groupId}/${selectedWish.wishId}`;
  }

  const handleOpenEditWishModal = (id: string) => {
    console.log('handleOpenEditWishModal', id);
    selectWish(id);
    setOpenEditWishModal(true);
  };

  const submitEditWish = (groupId: string, wishId: string) => {
    console.log('submitEditWish', groupId, wishId);
    //    selectWish();
    setOpenEditWishModal(false);
    mutate();
  };

  const closeEditWishModal = () => {
    console.log('closeEditWish');
    //    selectWish();
    setOpenEditWishModal(false);
  };

  const handleOpenDeleteWishModal = (id: string) => {
    console.log('handleOpenDeleteWishModal', id);
    selectWish(id);
    setOpenDeleteWishModal(true);
  };

  const submitDeleteWish = (groupId: string, wishId: string) => {
    console.log('submitDeleteWish', groupId, wishId);
    selectWish();
    setOpenDeleteWishModal(false);
    mutate();
  };

  const closeDeleteWishModal = () => {
    console.log('closeDeleteWish');
    //    selectWish();
    setOpenDeleteWishModal(false);
  };

  const handleOpenSubmitWishModal = (id: string) => {
    console.log('handleOpenSubmitWishModal', id);
    selectWish(id);
    setOpenSubmitWishModal(true);
  };

  const submitSubmitWish = (groupId: string, wishId: string) => {
    console.log('submitSubmitWish', groupId, wishId);
    //    selectWish();
    setOpenSubmitWishModal(false);
    mutate();
  };

  const closeSubmitWishModal = () => {
    console.log('closeSubmitWish');
    //    selectWish();
    setOpenSubmitWishModal(false);
  };

  const handleOpenReviewWishModal = (id: string) => {
    console.log('handleOpenReviewWishModal', id);
    selectWish(id);
    setOpenReviewWishModal(true);
  };

  const submitReviewWish = (groupId: string, wishId: string) => {
    console.log('submitReviewWish', groupId, wishId);
    //    selectWish();
    setOpenReviewWishModal(false);
    mutate();
  };

  const closeReviewWishModal = () => {
    console.log('closeReviewWish');
    //    selectWish();
    setOpenReviewWishModal(false);
  };

  const handleOpenApproveWishModal = (id: string) => {
    console.log('handleOpenApproveWishModal', id);
    selectWish(id);
    setOpenApproveWishModal(true);
  };

  const submitApproveWish = (groupId: string, wishId: string) => {
    console.log('submitApproveWish', groupId, wishId);
    //    selectWish();
    setOpenApproveWishModal(false);
    mutate();
  };

  const closeApproveWishModal = () => {
    console.log('closeApproveWish');
    //    selectWish();
    setOpenApproveWishModal(false);
  };

  const handleOpenScheduleWishModal = (id: string) => {
    console.log('handleOpenScheduleWishModal', id);
    selectWish(id);
    setOpenScheduleWishModal(true);
  };

  const submitScheduleWish = (groupId: string, wishId: string) => {
    console.log('submitScheduleWish', groupId, wishId);
    //    selectWish();
    setOpenScheduleWishModal(false);
    mutate();
  };

  const closeScheduleWishModal = () => {
    console.log('closeScheduleWish');
    //    selectWish();
    setOpenScheduleWishModal(false);
  };

  const handleOpenCancelWishModal = (id: string) => {
    console.log('handleOpenCancelWishModal', id);
    selectWish(id);
    setOpenCancelWishModal(true);
  };

  const submitCancelWish = (groupId: string, wishId: string) => {
    console.log('submitCancelWish', groupId, wishId);
    //    selectWish();
    setOpenCancelWishModal(false);
    mutate();
  };

  const closeCancelWishModal = () => {
    console.log('closeCancelWish');
    //    selectWish();
    setOpenCancelWishModal(false);
  };

  const handleOpenDeliverWishModal = (id: string) => {
    console.log('handleOpenDeliverWishModal', id);
    selectWish(id);
    setOpenDeliverWishModal(true);
  };

  const submitDeliverWish = (groupId: string, wishId: string) => {
    console.log('submitDeliverWish', groupId, wishId);
    //    selectWish();
    setOpenDeliverWishModal(false);
    mutate();
  };

  const closeDeliverWishModal = () => {
    console.log('closeDeliverWish');
    //    selectWish();
    setOpenDeliverWishModal(false);
  };

  const handleOpenStateWishModal = (id: string) => {
    console.log('handleOpenStateWishModal', id);
    selectWish(id);
    setOpenStateWishModal(true);
  };

  const submitStateWish = (groupId: string, wishId: string) => {
    console.log('submitStateWish', groupId, wishId);
    //    selectWish();
    setOpenStateWishModal(false);
    mutate();
  };

  const closeStateWishModal = () => {
    console.log('closeStateWish');
    //    selectWish();
    setOpenStateWishModal(false);
  };

  const handleOpenPanelWishModal = (id: string) => {
    console.log('handleOpenPanelWishModal', id);
    selectWish(id);
    setOpenPanelWishModal(true);
  };

  const submitPanelWish = (groupId: string, wishId: string) => {
    console.log('submitPanelWish', groupId, wishId);
    //    selectWish();
    setOpenPanelWishModal(false);
    mutate();
  };

  const closePanelWishModal = () => {
    console.log('closePanelWish');
    //    selectWish();
    setOpenPanelWishModal(false);
  };

  const value = {
    dataGroupList,
    dataWishList,
    isLoading,
    mutate,
    selectedGroup,
    selectGroup,
    selectedWish,
    selectWish,
    handleCreate: handleCreateWish,
    handleEdit: handleOpenEditWishModal,
    handleDelete: handleOpenDeleteWishModal,
    handleSubmit: handleOpenSubmitWishModal,
    handleReview: handleOpenReviewWishModal,
    handleApprove: handleOpenApproveWishModal,
    handleSchedule: handleOpenScheduleWishModal,
    handleDeliver: handleOpenDeliverWishModal,
    handleState: handleOpenStateWishModal,
    handleCancel: handleOpenCancelWishModal,
    handlePanel: handleOpenPanelWishModal,
    handleWish: handleWish
  };
  return (
    <WishContext.Provider value={value}>
      {children}
      <EditWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openEditWishModal} closeModal={closeEditWishModal} handleSubmit={submitEditWish} newWishType={newWishType} />
      <DeleteWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openDeleteWishModal} closeModal={closeDeleteWishModal} handleSubmit={submitDeleteWish} />
      <SubmitWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openSubmitWishModal} closeModal={closeSubmitWishModal} handleSubmit={submitSubmitWish} />
      <ReviewWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openReviewWishModal} closeModal={closeReviewWishModal} handleSubmit={submitReviewWish} />
      <ApproveWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openApproveWishModal} closeModal={closeApproveWishModal} handleSubmit={submitApproveWish} />
      <ScheduleWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openScheduleWishModal} closeModal={closeScheduleWishModal} handleSubmit={submitScheduleWish} />
      <DeliverWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openDeliverWishModal} closeModal={closeDeliverWishModal} handleSubmit={submitDeliverWish} />
      <StateWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openStateWishModal} closeModal={closeStateWishModal} handleSubmit={submitStateWish} />
      <CancelWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openCancelWishModal} closeModal={closeCancelWishModal} handleSubmit={submitCancelWish} />
      <PanelWishModal selectedGroup={selectedGroup} selectedWish={selectedWish} open={openPanelWishModal} closeModal={closePanelWishModal} handleSubmit={submitPanelWish} />
    </WishContext.Provider>
  );
};
