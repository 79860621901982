import { useState, useEffect } from 'react';
import { createGroup, updateGroup } from '../../../services/users';

import { AppMessage } from '../../../shared/UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const useStyles = makeStyles()((theme) => ({
  table: {
    width: '100%'
  },
  cell: {
    color: theme.palette.text.secondary,
    lineHeight: '1rem',
    padding: theme.spacing(1, 0.5)
  },
  helper: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  small: {
    fontWeight: 400,
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export const EditGroupModal = (props: any) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState(true);

  const [errorName, setErrorName] = useState('');
  const [errorDescription, setErrorDescription] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    if (props.selectedGroup) {
      setName(props.selectedGroup.name || '');
      setDescription(props.selectedGroup.description || '');
      setStatus(props.selectedGroup.status || false);
    } else {
      clearForm();
      clearErrors();
    }
  }, [props.selectedGroup])

  const clearForm = () => {
    setName('');
    setDescription('');
    setStatus(true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorName('');
    setErrorDescription('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (name?.length === 0) {
      setErrorName('Enter the group name');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.selectedGroup) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating user group: <strong>${name}</strong> ... ` });
    createGroup(name, description, status).then((data: any) => {
      console.log('createGroup', data);
      if (data) {
        setMessage({ type: 'success', description: `User Group: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(data.groupId);
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating User Group: <strong>${name}</strong> ... ` });
    updateGroup(props.selectedGroup?.groupId, name, description, status).then((data: any) => {
      console.log('updateGroup', data);
      if (data) {
        setMessage({ type: 'success', description: `User Group: <strong>${name}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(props.selectedGroup?.groupId);
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.selectedGroup && (
        <DialogTitle>Update User Group</DialogTitle>
      )}
      {!props.selectedGroup && (
        <DialogTitle>Create a new User Group</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        {props.selectedGroup && (
          <DialogContentText>Update the group details:</DialogContentText>
        )}
        {!props.selectedGroup && (
          <DialogContentText>Input the group details:</DialogContentText>
        )}
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={(e: any) => setName(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />
          <TextField
            id='description'
            label='description'
            value={description}
            error={errorDescription.length > 0}
            helperText={errorDescription}
            onChange={(e: any) => setDescription(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup value={status} onChange={(_e: any, val: any) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; enabled </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
