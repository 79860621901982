import { useState, useEffect } from 'react';
import { createProduct, updateProduct } from '../../../services/product';

import { AppMessage } from '../../../shared/UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const useStyles = makeStyles()((theme) => ({
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  errorText: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(0.5)
  },
  buttons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export const EditProductModal = (props: any) => {
  const [category, setCategory] = useState('');
  const [approvedBy, setApprovedBy] = useState('');
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [price, setPrice] = useState('');
  const [status, setStatus] = useState(true);

  const [errorCategory, setErrorCategory] = useState('');
  const [errorApprovedBy, setErrorApprovedBy] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorSku, setErrorSku] = useState('');
  const [errorPrice, setErrorPrice] = useState('');


  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  useEffect(() => {
    clearForm();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedProduct]);

  const clearForm = () => {
    setCategory(props.selectedProduct?.category || '');
    setApprovedBy(props.selectedProduct?.approvedBy || '');
    setName(props.selectedProduct?.name || '');
    setSku(props.selectedProduct?.sku || '');
    setPrice(props.selectedProduct?.price || '');
    setStatus(props.selectedProduct?.status ?? true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorCategory('');
    setErrorApprovedBy('');
    setErrorName('');
    setErrorSku('');
    setErrorPrice('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (category?.length === 0) {
      setErrorCategory('Select the product category');
      return false;
    }
    if (approvedBy?.length === 0) {
      setErrorApprovedBy('Select the approval manager');
      return false;
    }
    if (name?.length === 0) {
      setErrorName('Enter the product name');
      return false;
    }
    if (sku?.length === 0) {
      setErrorSku('Enter the product SKU');
      return false;
    }
    if (price?.length === 0) {
      setErrorPrice('Enter the product price');
      return false;
    }

    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.selectedProduct) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating Leather Product for: <strong>${name}</strong> ... ` });
    createProduct(category, approvedBy, sku.trim(), name.trim(), price, status).then((data: any) => {
      console.log('createProduct', data);
      if (data) {
        setMessage({ type: 'success', description: `Leather Product: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(data.groupId);
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating Leather Product: <strong>${name}</strong> ... ` });
    updateProduct(props.selectedProduct?.productId, category, approvedBy, sku, name.trim(), price, status).then((data: any) => {
      console.log('updateProduct', data);
      if (data) {
        setMessage({ type: 'success', description: `Leather Product: <strong>${name}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(props.selectedProduct?.productId);
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      {props.selectedProduct && (
        <DialogTitle>Update a Leather Product</DialogTitle>
      )}
      {!props.selectedProduct && (
        <DialogTitle>Create a new Leather Product</DialogTitle>
      )}
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Enter the product details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl className={classes.formcontrol} fullWidth>
                <InputLabel id='category-label'>category</InputLabel>
                <Select
                  labelId='category-label'
                  id='category'
                  label="category"
                  value={category}
                  onChange={(e: any) => setCategory(e.target.value)}
                >
                  <MenuItem value="kelly">kelly classic</MenuItem>
                  <MenuItem value="birkin">birkin classic</MenuItem>
                  <MenuItem value="kellyx">kelly exotic</MenuItem>
                  <MenuItem value="birkinx">birkin exotic</MenuItem>
                </Select>
                <FormHelperText className={classes.errorText}>{errorCategory}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.formcontrol} fullWidth>
                <InputLabel id='approvedBy-label'>approve by</InputLabel>
                <Select
                  labelId='approvedBy-label'
                  id='approvedBy'
                  label="approve by"
                  value={approvedBy}
                  onChange={(e: any) => setApprovedBy(e.target.value)}
                >
                  <MenuItem value="director">Store Director</MenuItem>
                  <MenuItem value="administrator">Leather Manager</MenuItem>
                  <MenuItem value="manager">Métier Line Manager</MenuItem>
                </Select>
                <FormHelperText className={classes.errorText}>{errorApprovedBy}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <TextField
            id='name'
            label='name'
            value={name}
            error={errorName.length > 0}
            helperText={errorName}
            onChange={(e: any) => setName(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id='sku'
                label='SKU'
                value={sku}
                error={errorSku.length > 0}
                helperText={errorSku}
                onChange={(e: any) => setSku(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='price'
                label='price'
                value={price}
                error={errorPrice.length > 0}
                helperText={errorPrice}
                onChange={(e: any) => setPrice(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center" className={classes.buttons}>
            <Grid item>
              <ToggleButtonGroup color="standard" value={status} onChange={(_e: any, val: any) => setStatus(val)} aria-label="status" exclusive>
                <ToggleButton value={true} aria-label="enabled"><CheckCircleOutlineIcon /> &nbsp; active </ToggleButton>
                <ToggleButton value={false} aria-label="disabled"><CancelOutlinedIcon /> &nbsp; disabled </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
