import clsx from 'clsx';

import { EState, EBool } from '../../services/types';

import { WishClient } from './WishClient';
import { WishActivity } from './WishActivity';
import { WishReview } from './WishReview';
import { WishPrevProduct } from './WishPrevProduct';
import { WishProduct } from './WishProduct';
import { WishNotes } from './WishNotes';

import Approved from '../../assets/background/approved.png';
import Denied from '../../assets/background/denied.png';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const useStyles: any = makeStyles()((theme) => ({
  grid: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(1, 3, 3, 3),
    marginBottom: theme.spacing(3),
  },
  approved: {
    backgroundImage: `url('${Approved}')`,
    backgroundPosition: 'bottom right 10px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100px 100px',
  },
  denied: {
    backgroundImage: `url('${Denied}')`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100px 100px',
  }
}));

export const WishPanels = (props: any) => {
  const { selectedWish } = props;

  const { classes } = useStyles();
  return (
    <>
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={3}>
          <Paper className={classes.paper}><WishClient selectedWish={selectedWish} /></Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={clsx(classes.paper, { [classes.denied]: (selectedWish?.state === EState.denied), [classes.approved]: (selectedWish?.isApproved === EBool.true)})}><WishProduct selectedWish={selectedWish} /></Paper>
          <Paper className={classes.paper}><WishPrevProduct selectedWish={selectedWish} /></Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}><WishReview selectedWish={selectedWish} /></Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.paper}><WishActivity selectedWish={selectedWish} /></Paper>
        </Grid>
        <Grid item xs={8}>
          <Paper className={classes.paper}><WishNotes selectedWish={selectedWish} /></Paper>
        </Grid>
      </Grid>
    </>
  );
};
