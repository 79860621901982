import { useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import clsx from 'clsx';

import { EditGroupUserModal } from './EditGroupUserModal';

import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import LaunchIcon from '@mui/icons-material/Launch';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import GroupIcon from '@mui/icons-material/Group';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  row: {
    cursor: 'pointer'
  },
  cell: {
    lineHeight: '1rem',
    padding: theme.spacing(1, 2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light,
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
}));

export const UserTable = (props: any) => {
  const { data, isLoading, mutate, selectedGroup, selectUser, selectedUser } = props;
  const [openEditGroupUserModal, setOpenEditGroupUserModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenEditGroupUserModal = (id: string) => {
    console.log('handleOpenEditGroupUserModal', id);
    selectUser(id);
    setOpenEditGroupUserModal(true);
  };

  const submitEditGroupUser = (groupId: string, userId: string) => {
    console.log('submitEditGroupUser', groupId, userId);
    selectUser();
    setOpenEditGroupUserModal(false);
    mutate();
  };

  const closeEditGroupUserModal = () => {
    console.log('closeEditGroupUser');
    selectUser();
    setOpenEditGroupUserModal(false);
  };

  const handleUser = (userId: string) => {
    navigate(`/user/${userId}`);
  };

  const { classes } = useStyles();
  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>User ID</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Username</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Name</span></TableCell>
            <TableCell className={classes.cell} align="left"><span className={classes.heading}>Email</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Login Count</span></TableCell>
            <TableCell className={classes.cell} align="center"><span className={classes.heading}>Last Login</span></TableCell>
            <TableCell className={classes.cell} align="right" style={{ minWidth: '60px' }}>
              <Tooltip title="create new user"><IconButton color="primary" onClick={() => props.handleCreateUser()}><AddIcon /></IconButton></Tooltip>
              {isLoading && (
                <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
              )}
              {!isLoading && (
                <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((row: any) => (
            <TableRow component="tr" key={row.userId} onClick={() => selectUser(row.userId)} className={clsx(classes.row, { [classes.selected]: (selectedUser?.userId === row.userId) })}>
              <TableCell className={classes.cell} align="center">{row.userId}</TableCell>
              <TableCell className={classes.cell} align="left"><b>{row.username}</b></TableCell>
              <TableCell className={classes.cell} align="left">{row.name}</TableCell>
              <TableCell className={classes.cell} align="left">{row.email}</TableCell>
              <TableCell className={classes.cell} align="center">{row.numLogins}</TableCell>
              <TableCell className={classes.cell} align="center">{row.loginAt ? format(new Date(row.loginAt), 'dd-MMM-yyyy HH:mm') : ''}</TableCell>
              <TableCell className={classes.cell} align="right">
                <Tooltip title="edit"><IconButton color="primary" onClick={() => handleOpenEditGroupUserModal(row.userId)}><GroupIcon /></IconButton></Tooltip>
                <Tooltip title="delete"><IconButton color="primary" onClick={() => handleUser(row.userId)}><LaunchIcon /></IconButton></Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <EditGroupUserModal selectedGroup={selectedGroup} selectedUser={selectedUser} open={openEditGroupUserModal} closeModal={closeEditGroupUserModal} handleSubmit={submitEditGroupUser} />
    </>
  );
};
