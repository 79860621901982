import { EState } from '../../services/types';

import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';

const useStyles: any = makeStyles()((theme) => ({
  button: {
    // margin: theme.spacing(3)
  },
}));

export const WishActionButton = (props: any) => {
  const { selectedWish, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver } = props;
  const classes = useStyles();
  if (selectedWish) {
    switch (selectedWish.state) {
      case EState.created:   return (<><Button variant='outlined' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleSubmit(selectedWish.wishId)}>Reject</Button> <Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleSubmit(selectedWish.wishId)}>Submit for Review</Button></>);
      case EState.submitted:  return (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleReview(selectedWish.wishId)}>Review</Button></>);
      case EState.rejected:  return (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleReview(selectedWish.wishId)}>Submit for Review</Button></>);
      case EState.reviewed:  return (<><Button variant='outlined' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleApprove(selectedWish.wishId)}>Deny</Button> <Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleApprove(selectedWish.wishId)}>Approve</Button></>);
      case EState.approved:  return (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleSchedule(selectedWish.wishId)}>Schedule</Button></>);
      case EState.denied:    return (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleApprove(selectedWish.wishId)}>Approve</Button></>);
      case EState.scheduled: return (<><Button variant='contained' color='primary' size='small' disableElevation className={classes.button} onClick={() => handleDeliver(selectedWish.wishId)}>Mark as Delivered</Button></>);
    }
  }
  return (<></>);
};
