import { makeStyles } from 'tss-react/mui';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles()((theme) => ({
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: '#fafafa',
  },
  progress: {
    marginTop: theme.spacing(0.5),
  },
}));

export interface IAppMessage {
  message?: {
    type: string;
    description: string;
  };
  isLoading?: boolean;
}

export const AppMessage = ({ message, isLoading }: IAppMessage) => {
  const { classes } = useStyles();
  return (
    <>
      {message && message.description && (
        <Alert
          variant='outlined'
          severity={message.type as any}
          className={classes.alert}
          action={isLoading ? <div className={classes.progress}><CircularProgress color="secondary" size={20} /></div> : <></>}
        >
          <span dangerouslySetInnerHTML={{ __html: message.description }}></span>
        </Alert>
      )}
    </>)
};
