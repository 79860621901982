import { useState } from "react";
import clsx from "clsx";

import { Menu } from "./Menu";

import NavBarToggleIcon from "../../assets/nav/toggle-icon.svg";

import { Drawer, Box, IconButton, useTheme } from "@mui/material";
import { makeStyles } from 'tss-react/mui';

interface IDrawerLayout {
  drawerWidth: number;
}

const useStyles = makeStyles<IDrawerLayout>()((theme, props) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: theme.palette.secondary.main,
  },
  drawerPaper: {
    position: 'relative',
    overflow: 'visible',
    width: props.drawerWidth,
    background: theme.palette.secondary.main
  },
  toggleBtn: {
    position: 'absolute',
    top: 81,
    right: -23,
    [theme.breakpoints.down('xs')]: {
      top: 65,
    },
  },
  drawerOpen: {
    position: 'relative',
    overflow: 'visible',
    height: '100%',
    width: props.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
    background: theme.palette.secondary.main
  },
  drawerClose: {
    height: '100%',
    position: 'relative',
    overflow: 'visible',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shortest,
    }),
    borderRight: 'none',
    maxWidth: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
      maxWidth: theme.spacing(9) + 1,
    },
    background: theme.palette.secondary.main,
  },
  rotate: {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
    }),
  },
}));

interface Props extends IDrawerLayout {
  window?: () => Window;
  setMobileOpen: (value: boolean) => void;
  mobileOpen: boolean;

}

export function DrawerLayout({ window, drawerWidth, setMobileOpen, mobileOpen }: Props) {
  const { classes } = useStyles({ drawerWidth });
  const theme = useTheme();
  const [open, _setOpen] = useState(localStorage.getItem('isOpen') === 'false' ? false : true);
  const container = window !== undefined ? () => window().document.body : undefined;

  const setOpen = (val: boolean) => {
    localStorage.setItem('isOpen', val.toString());
    _setOpen(val);
  }

  return (
    <nav className={classes.drawer}>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={() => setMobileOpen(!mobileOpen)}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <IconButton className={clsx(classes.toggleBtn, classes.rotate)}
            style={{ transform: mobileOpen ? 'rotate(0)' : 'rotate(180deg)' }}
            onClick={() => setMobileOpen(!mobileOpen)}>
            <img src={NavBarToggleIcon} alt="toggle" />
          </IconButton>
          <Menu isOpen={mobileOpen} isMobile={true} onClose={() => setMobileOpen(false)} />
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <Drawer
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          variant="permanent"
        >
          <IconButton className={clsx(classes.toggleBtn, classes.rotate)}
            style={{ transform: open ? 'rotate(0deg)' : 'rotate(180deg)' }}
            onClick={() => setOpen(!open)}>
            <img src={NavBarToggleIcon} alt="toggle" />
          </IconButton>
          <Menu isOpen={open} />
        </Drawer>
      </Box>
    </nav>
  );
}
