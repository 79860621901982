import { useEffect, useState, useMemo } from 'react';

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { useWishStore } from '../../hooks/context/useWishStore';
import { useNavigate } from "react-router-dom";

import { EState } from '../../services/types';
import { useApiBudgetList } from '../../hooks/api/useApiBudgetList';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { AppSkeleton } from '../../shared/UI/AppSkeleton';
import { WishDetails } from '../../shared/Wish/WishDetails';
import { WishBudget } from '../../shared/Wish/WishBudget';
import { GroupTable } from '../../shared/Table/GroupTable';
import { EditBudgetModal } from '../../shared/Modal/EditBudgetModal';

import { Years } from '../../shared/Card/Years';
import { Months } from '../../shared/Card/Months';
import { Days } from '../../shared/Card/Days';
import { ScheduleTabs } from './components/ScheduleTabs';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1, 3, 3, 3),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

export const Schedule = () => {
  const { isAuthorized } = useAuthStore();
  const { dataGroupList, dataWishList, selectedGroup, selectGroup, selectedWish, selectWish, isLoading, mutate, handleCreate, handleEdit, handleDelete, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handlePanel } = useWishStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const now = new Date();
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth());
  const [day, setDay] = useState(0);

  const { data: dataBudgetList, isLoading: isLoadingBudgetList, mutate: mutateBudgetList } = useApiBudgetList(selectedGroup?.groupId, year);
  const [openEditBudgetModal, setOpenEditBudgetModal] = useState(false);

  // const selectedDate = useMemo(() => new Date(year, month, day || 1).toISOString(), [year, month, day]);
  // useEffect(() => console.log('selectedDate', selectedDate.substring(0, 10), year, month, day), [selectedDate, year, month, day]);

  const days = useMemo(() => {
    const dys = [] as string[];
    if (day > 0) {
      dys.push(`${day}`);
    } else {
      const numDays = new Date(year, month + 1, 0).getDate();
      for (var dd = 1; dd <= numDays; dd++) {
        dys.push(`${dd}`);
      }
    }
    return dys;
  }, [year, month, day]);


  const selectedBudget = useMemo(() => {
    // console.log('dataBudgetList', dataBudgetList?.length, dataWishList?.length);
    if (dataBudgetList && dataWishList) {
      const budget = dataBudgetList?.find((x: any) => Number(x.year) === Number(year) && Number(x.month) - 1 === Number(month) && x.status === true);
      // console.log('budget', year, month, budget);
      if (budget) {
        const byr = parseInt(budget.year);
        const bmn = parseInt(budget.month) - 1;
        budget.kellyClassicSales = 0;
        budget.kellyExoticSales = 0;
        budget.birkinClassicSales = 0;
        budget.birkinExoticSales = 0;
        for (const wish of dataWishList) {
          if (wish.scheduleDate && (wish.state === EState.scheduled || wish.state === EState.completed)) {
            const dt = new Date(wish.scheduleDate);
            const year = dt.getFullYear();
            const month = dt.getMonth();
            // console.log('wish', year, month, byr, bmn, byr === year, bmn === month);
            if (byr === year && bmn === month) {
              // console.log('wish', wish, year, month, byr, bmn, byr === year, bmn === month);

              switch (wish.productCategory) {
                case "kelly": budget.kellyClassicSales += Number(wish.price); break;
                case "kellyx": budget.kellyExoticSales += Number(wish.price); break;
                case "birkin": budget.birkinClassicSales += Number(wish.price); break;
                case "birkinx": budget.birkinExoticSales += Number(wish.price); break;
                default: console.log('ERROR', 'no category', wish);
              }
            }
          }
        }
        budget.kellySales = budget.kellyClassicSales + budget.kellyExoticSales;
        budget.birkinSales = budget.birkinClassicSales + budget.birkinExoticSales;
        budget.kellyAvailable = (budget.kelly || 0) - budget.kellySales;
        budget.birkinAvailable = (budget.birkin || 0) - budget.birkinSales;
        budget.totalBudget = (budget.kelly || 0) + (budget.birkin || 0);
        budget.totalSales = budget.kellySales + budget.birkinSales;
        budget.totalAvailable = budget.kellyAvailable + budget.birkinAvailable;
        // console.log('budget', budget);
        return budget;
      }
    }
  }, [dataBudgetList, dataWishList, year, month]);

  const handleOpenEditBudgetModal = () => {
    console.log('handleOpenEditBudgetModal');
    setOpenEditBudgetModal(true);
  };

  const submitEditBudget = (groupId: string, year: string, month: string) => {
    console.log('submitEditBudget', groupId, year, month);
    setOpenEditBudgetModal(false);
    mutate();
  };

  const closeEditBudgetModal = () => {
    console.log('closeEditBudget');
    setOpenEditBudgetModal(false);
  };

  const handleWish = (id: string) => {
    console.log('handleWish', id);
    selectWish(id);
    if (selectedWish) navigate(`/wish/${selectedWish.groupId}/${selectedWish.wishId}`);
  }

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between">
        <Grid item>
          <div className={classes.root}>
            <h1 className={classes.title}>Schedule</h1>
            <h4 className={classes.subtitle}><span>View</span> &amp; <span>Update</span> the <span>delivery date</span> of Leather Wishes</h4>
          </div>
        </Grid>
        <Grid item style={{ paddingTop: '40px' }}>
          <Button size="small" variant="contained" color="primary" disableElevation endIcon={<LibraryBooksIcon />} className={classes.button} onClick={() => handleCreate('appointment')}>Create Appointment</Button>
          <Button size="small" variant="contained" color="primary" disableElevation endIcon={<PhotoFilterIcon />} className={classes.button} onClick={() => handleCreate('wish')}>Create Wish</Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          {(!Array.isArray(dataGroupList)) && <AppSkeleton className={classes.skeleton} />}
          {(Array.isArray(dataGroupList)) && (
            <Paper className={classes.paper}>
              <GroupTable data={dataGroupList} isLoading={isLoading} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} />
            </Paper>
          )}
          <Years year={year} setYear={setYear} />
          <Months year={year} month={month} setMonth={setMonth} />
          <Days year={year} month={month} day={day} setDay={setDay} />
          {selectedWish?.wishId && (
            <Paper className={classes.paper}>
              <WishDetails
                isLoading={isLoading}
                mutate={mutate}
                selectedWish={selectedWish}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleSubmit={handleSubmit}
                handleReview={handleReview}
                handleApprove={handleApprove}
                handleSchedule={handleSchedule}
                handleDeliver={handleDeliver}
                handleCancel={handleCancel}
                handleState={handleState}
                handlePanel={handlePanel}
                handleWish={handleWish}
              />
            </Paper>
          )}
          <Paper className={classes.paper}>
            <WishBudget
              selectedBudget={selectedBudget}
              year={year}
              month={month}
              data={dataBudgetList}
              isLoading={isLoadingBudgetList}
              mutate={mutateBudgetList}
              handleEdit={handleOpenEditBudgetModal}
            />
          </Paper>
        </Grid>
        <Grid item sm={9}>
          <ScheduleTabs
            year={year}
            month={month}
            days={days}
            data={dataWishList?.filter((x: any) => x.groupId === selectedGroup.groupId && (x.state === EState.scheduled || x.state === EState.completed))}
            isLoading={isLoading}
            mutate={mutate}
            selectWish={selectWish}
            selectedWish={selectedWish}
            selectedGroup={selectedGroup}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handleSubmit={handleSubmit}
            handleReview={handleReview}
            handleApprove={handleApprove}
            handleSchedule={handleSchedule}
            handleDeliver={handleDeliver}
            handleCancel={handleCancel}
            handleState={handleState}
            handlePanel={handlePanel}
          />
        </Grid>
      </Grid>
      <EditBudgetModal selectedGroup={selectedGroup} selectedBudget={selectedBudget} year={year} month={month} open={openEditBudgetModal} closeModal={closeEditBudgetModal} handleSubmit={submitEditBudget} />
    </AppLayout>
  );
};
