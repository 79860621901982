import { useState, useEffect, useMemo } from 'react';

import { EState } from '../../services/types';
import { scheduleWish } from '../../services/wish';

import { WishTitle } from '../Wish/WishTitle';
import { AppMessage } from '../UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    fontWeight: 300,
    fontSize: '1em',
  },
  content: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

export const ScheduleWishModal = (props: any) => {
  const [price, setPrice] = useState('');
  const [notes, setNotes] = useState('');
  const [scheduledAt, setScheduledAt] = useState('');

  const [errorPrice, setErrorPrice] = useState('');
  const [errorNotes, setErrorNotes] = useState('');
  const [errorScheduledAt, setErrorScheduledAt] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const scheduleDate = useMemo(() => { if (scheduledAt) { const dt = new Date(scheduledAt); dt.setHours(12, 0, 0, 0); return dt.toISOString() } else { return new Date().toISOString() } }, [scheduledAt]);

  const clearForm = () => {
    setPrice(props.selectedWish?.price || props.selectedWish?.productPrice || '');
    setNotes(props.selectedWish?.scheduleNotes || '');
    setScheduledAt(props.selectedWish?.scheduleDate);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorPrice('');
    setErrorNotes('');
    setErrorScheduledAt('');
  };

  useEffect(() => {
    clearForm();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedGroup, props.selectedWish])

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (price.length === 0) {
      setErrorPrice('Enter the price');
      return false;
    }
    if (scheduledAt.length === 0) {
      setErrorScheduledAt('Enter the date');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleSchedule();
    }
  };

  const handleSchedule = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Scheduling Wish: <strong>${props.selectedWish?.name}</strong> ... ` });
    scheduleWish(props.selectedWish.groupId, props.selectedWish.wishId, price, notes, scheduleDate, EState.scheduled).then((result: any) => {
      if (result.status) {
        setMessage({ type: 'success', description: `Wish: <strong>${props.selectedWish?.name}</strong> scheduled <small>for ${new Date(scheduleDate).toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(result.data);
      } else {
        setMessage({ type: 'error', description: result.message });
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent='space-between'>
        <Grid item>
          <DialogTitle>Schedule <WishTitle wishType={props.selectedWish?.wishType} /></DialogTitle>
        </Grid>
        <Grid item style={{ textAlign: 'right' }}>
          <DialogTitle className={classes.subtitle}>{props.selectedWish?.name} - {props.selectedWish?.productName} {props.selectedWish?.bag}</DialogTitle>
        </Grid>
      </Grid>

      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <div className={classes.content}>
            <h3>{props.selectedWish?.name}</h3>
            <h5>{props.selectedWish?.productName} {props.selectedWish?.bag}</h5>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                id='price'
                label='price'
                value={price}
                error={errorPrice.length > 0}
                helperText={errorPrice}
                onChange={(e: any) => setPrice(e.target.value.trim())}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat='DD MMMM YYYY'
                  label='schedule date'
                  value={scheduledAt}
                  onChange={(val: any) => setScheduledAt(val)}
                  renderInput={(params: any) => (
                    <TextField {...params}
                      error={errorScheduledAt.length > 0}
                      helperText={errorScheduledAt}
                      margin='normal'
                      variant='outlined'
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <TextField
            id='notes'
            label='notes'
            value={notes}
            error={errorNotes.length > 0}
            helperText={errorNotes}
            onChange={(e: any) => setNotes(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            multiline
            rows='4'
          />
          <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
