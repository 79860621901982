import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "../../hooks/context/useAuthStore";
import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
}));
export const Home = () => {
  const { isAuthorized } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      navigate('/overview');
    } else {
      navigate('/logout');
    }
  }, [isAuthorized, navigate]);

  const { classes } = useStyles();
  return (
    <AppLayout>
      <div className={classes.root}>
      </div>
    </AppLayout>
  );
};
