import { useApiUserDetails } from '../../hooks/admin/useApiUserDetails';

import { AppSkeleton } from "../../shared/UI/AppSkeleton";
import { Avatar } from '../../shared/Picture/Avatar';
import { UserProfile } from './profile/UserProfile';
import { Groups } from './profile/Groups';

import { makeStyles } from 'tss-react/mui';
import { Paper, Grid } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  grid: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#333042',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#333042',
    '& span': {
      fontWeight: 500
    }
  },
  detail: {
  },
  itemTitle: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  },
  itemValue: {
    fontSize: '0.8rem',
    fontWeight: 300,
    textAlign: 'left'
  },
  verified: {
    color: theme.palette.success.dark,
  },
  picture: {
    width: '100%',
    height: 'auto',
    borderRadius: '50%',
  },
  skeleton: {
    height: '150px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  }
}));

export const Profile = (props: any) => {
  const { userId } = props;
  const { data, isLoading, mutate } = useApiUserDetails(userId);
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      {!(data && data.updatedAt) && (
        <AppSkeleton className={classes.skeleton} />
      )}
      {(data && data.updatedAt) && (
        <Grid container justifyContent="flex-start" spacing={3}>
          <Grid item lg={2} md={6} sm={12} xs={12}><Paper className={classes.paper}><Avatar size="large" picture={data.picture} /></Paper></Grid>
          <Grid item lg={10} md={6} sm={12} xs={12}>

            <Grid container justifyContent="flex-start" spacing={3} className={classes.grid}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <UserProfile data={data} isLoading={isLoading} mutate={mutate} />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Groups selectedUser={data} />
              </Grid>
            </Grid>

          </Grid>
        </Grid>

      )}
    </div>
  );
}