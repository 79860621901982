import useSWR from 'swr';

import {useAuthStore} from "../context/useAuthStore";
import { getGroups } from "../../services/users";


const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

const apiGroupList = ([_]: any[]) => getGroups();

export const useApiGroupList = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['useApiGroupList'] : null, apiGroupList, options)
  return {
    data: data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};

