import { format } from 'date-fns';

import { WishTitle } from './WishTitle';
import { WishState } from './WishState';
import { WishActionIcon } from './WishActionIcon';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles: any = makeStyles()((theme) => ({
  icons: {
    width: '100%'
  },
  table: {
    width: '100%',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
    lineHeight: theme.spacing(5)
  },
  cell: {
    padding: theme.spacing(1, 2),
  },
  label: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right'
  },
  stateLabel: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right',
    marginTop: theme.spacing(0.5)
  },
  text: {
    fontSize: '0.85em',
    fontWeight: 400,
  },
}));

export const WishDetails = (props: any) => {
  const { selectedWish, isLoading, mutate, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleState, handleEdit, handleDelete, handlePanel, handleWish } = props;

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}>
            <Grid container justifyContent="space-between" className={classes.icons}>
              <Grid item><div className={classes.heading}><WishTitle wishType={selectedWish?.wishType} /></div></Grid>
              <Grid item>
                {isLoading && (
                  <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
                )}
                {!isLoading && (
                  <Tooltip title="refresh"><IconButton color="primary" onClick={() => mutate()}><RefreshIcon /></IconButton></Tooltip>
                )}
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow component="tr">
          <TableCell className={classes.cell}>
            <Grid container spacing={1} style={classes.grid}>
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.clientId && (<><Grid item xs={4} className={classes.label}>CEGID N<sup>o</sup>: </Grid><Grid item xs={8} className={classes.text} style={{ paddingTop: '11px' }}>{selectedWish.clientId}</Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.name && (<><Grid item xs={4} className={classes.label}>Name: </Grid><Grid item xs={8} className={classes.text}><b>{selectedWish.name}</b></Grid></>)}
              {selectedWish.email && (<><Grid item xs={4} className={classes.label}>Email: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.email}</Grid></>)}
              {selectedWish.phone && (<><Grid item xs={4} className={classes.label}>Phone: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.phone}</Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.advisorUserName && (<><Grid item xs={4} className={classes.label}>Advisor: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.advisorUserName}</Grid></>)}
              {selectedWish.productName && (<><Grid item xs={4} className={classes.label}>Product: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.productName} {selectedWish.bag}</Grid></>)}
              {selectedWish.price && (<><Grid item xs={4} className={classes.label}>Price: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.price}</Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.createdAt && (<><Grid item xs={4} className={classes.label}>Created: </Grid><Grid item xs={8} className={classes.text}>{format(new Date(selectedWish.createdAt), 'dd-MMM-yyyy hh:mm')}</Grid></>)}
              {selectedWish.scheduleDate && (<><Grid item xs={4} className={classes.label}>Scheduled: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.scheduleDate ? format(new Date(selectedWish.scheduleDate), 'dd-MMM-yyyy') : ''}</Grid></>)}
              {selectedWish.deliveredAt && (<><Grid item xs={4} className={classes.label}>Delivered: </Grid><Grid item xs={8} className={classes.text}>{selectedWish.deliveredAt ? format(new Date(selectedWish.deliveredAt), 'dd-MMM-yyyy') : ''}</Grid></>)}
              {selectedWish.updatedAt && (<><Grid item xs={4} className={classes.label}>Updated: </Grid><Grid item xs={8} className={classes.text}>{format(new Date(selectedWish.updatedAt), 'dd-MMM-yyyy hh:mm')}</Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
              {selectedWish.state && (<><Grid item xs={4} className={classes.stateLabel}>State: </Grid><Grid item xs={8} className={classes.text}><WishState state={selectedWish.state} /></Grid></>)}
              <Grid item xs={4} className={classes.label}></Grid><Grid item xs={8} className={classes.text}></Grid>
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow component="tr">
          <TableCell className={classes.cell} align="right">
            <WishActionIcon
              selectedWish={selectedWish}
              handleSubmit={handleSubmit}
              handleReview={handleReview}
              handleApprove={handleApprove}
              handleSchedule={handleSchedule}
              handleDeliver={handleDeliver}
              handleState={handleState}
            />
            <Tooltip title="edit"><IconButton color="primary" onClick={() => handleEdit(selectedWish.wishId)}><EditIcon /></IconButton></Tooltip>
            <Tooltip title="delete"><IconButton color="primary" onClick={() => handleDelete(selectedWish.wishId)}><DeleteIcon /></IconButton></Tooltip>
            <Tooltip title="view wish"><IconButton color="primary" onClick={() => handlePanel(selectedWish.wishId)}><LaunchIcon /></IconButton></Tooltip>
            <Tooltip title="go to wish"><IconButton color="primary" onClick={() => handleWish(selectedWish.wishId)}><ArrowRightIcon /></IconButton></Tooltip>
          </TableCell>
        </TableRow>

      </TableBody>
    </Table>

  );
};
