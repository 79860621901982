import { useState, useEffect } from 'react';

import { EState } from '../../services/types';
import { reviewWish } from '../../services/wish';

import { WishTitle } from '../Wish/WishTitle';
import { AppMessage } from '../UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    fontWeight: 400,
    fontSize: '0.8em',
  },
  notes: {
    fontSize: '0.8em',
  }
}));

export const ReviewWishModal = (props: any) => {
  const [cegidCreatedAt, setCegidCreatedAt] = useState('');
  const [totalPurchase, setTotalPurchase] = useState('');
  const [annualPurchase, setAnnualPurchase] = useState('');
  const [annualCPurchase, setAnnualCPurchase] = useState('');
  const [prevAnnualPurchase, setPrevAnnualPurchase] = useState('');
  const [prevAnnualCPurchase, setPrevAnnualCPurchase] = useState('');
  const [prevKBC1, setPrevKBC1] = useState('');
  const [prevKBC1PurchasedAt, setPrevKBC1PurchasedAt] = useState('');
  const [prevKBC2, setPrevKBC2] = useState('');
  const [prevKBC2PurchasedAt, setPrevKBC2PurchasedAt] = useState('');
  const [prevKBC3, setPrevKBC3] = useState('');
  const [prevKBC3PurchasedAt, setPrevKBC3PurchasedAt] = useState('');
  const [prevKBC4, setPrevKBC4] = useState('');
  const [prevKBC4PurchasedAt, setPrevKBC4PurchasedAt] = useState('');
  const [sinceKBCPurchase, setSinceKBCPurchase] = useState('');
  const [amountRTW, setAmountRTW] = useState('');
  const [amountA, setAmountA] = useState('');
  const [amountMP, setAmountMP] = useState('');
  const [amountBW, setAmountBW] = useState('');
  const [amountZ, setAmountZ] = useState('');
  const [amountFS, setAmountFS] = useState('');
  const [amountMOE, setAmountMOE] = useState('');
  const [amountDOHA, setAmountDOHA] = useState('');
  const [amountKWT, setAmountKWT] = useState('');
  const [amountAD, setAmountAD] = useState('');
  const [amountBAH, setAmountBAH] = useState('');
  const [amountOther, setAmountOther] = useState('');
  const [notes, setNotes] = useState('');

  const [errorCegidCreatedAt, setErrorCegidCreatedAt] = useState('');
  const [errorTotalPurchase, setErrorTotalPurchase] = useState('');
  const [errorAnnualPurchase, setErrorAnnualPurchase] = useState('');
  const [errorAnnualCPurchase, setErrorAnnualCPurchase] = useState('');
  const [errorPrevAnnualPurchase, setErrorPrevAnnualPurchase] = useState('');
  const [errorPrevAnnualCPurchase, setErrorPrevAnnualCPurchase] = useState('');
  const [errorPrevKBC1, setErrorPrevKBC1] = useState('');
  const [errorPrevKBC1PurchasedAt, setErrorPrevKBC1PurchasedAt] = useState('');
  const [errorPrevKBC2, setErrorPrevKBC2] = useState('');
  const [errorPrevKBC2PurchasedAt, setErrorPrevKBC2PurchasedAt] = useState('');
  const [errorPrevKBC3, setErrorPrevKBC3] = useState('');
  const [errorPrevKBC3PurchasedAt, setErrorPrevKBC3PurchasedAt] = useState('');
  const [errorPrevKBC4, setErrorPrevKBC4] = useState('');
  const [errorPrevKBC4PurchasedAt, setErrorPrevKBC4PurchasedAt] = useState('');
  const [errorSinceKBCPurchase, setErrorSinceKBCPurchase] = useState('');
  const [errorAmountRTW, setErrorAmountRTW] = useState('');
  const [errorAmountA, setErrorAmountA] = useState('');
  const [errorAmountMP, setErrorAmountMP] = useState('');
  const [errorAmountBW, setErrorAmountBW] = useState('');
  const [errorAmountZ, setErrorAmountZ] = useState('');
  const [errorAmountFS, setErrorAmountFS] = useState('');
  const [errorAmountMOE, setErrorAmountMOE] = useState('');
  const [errorAmountDOHA, setErrorAmountDOHA] = useState('');
  const [errorAmountKWT, setErrorAmountKWT] = useState('');
  const [errorAmountAD, setErrorAmountAD] = useState('');
  const [errorAmountBAH, setErrorAmountBAH] = useState('');
  const [errorAmountOther, setErrorAmountOther] = useState('');
  const [errorNotes, setErrorNotes] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const clearForm = () => {
    setCegidCreatedAt(props.selectedWish?.cegidCreatedAt || '');
    setTotalPurchase(props.selectedWish?.totalPurchase || '');
    setAnnualPurchase(props.selectedWish?.annualPurchase || '');
    setAnnualCPurchase(props.selectedWish?.annualCPurchase || '');
    setPrevAnnualPurchase(props.selectedWish?.prevAnnualPurchase || '');
    setPrevAnnualCPurchase(props.selectedWish?.prevAnnualCPurchase || '');
    setPrevKBC1(props.selectedWish?.prevKBC1 || '');
    setPrevKBC1PurchasedAt(props.selectedWish?.prevKBC1PurchasedAt || '');
    setPrevKBC2(props.selectedWish?.prevKBC2 || '');
    setPrevKBC2PurchasedAt(props.selectedWish?.prevKBC2PurchasedAt || '');
    setPrevKBC3(props.selectedWish?.prevKBC3 || '');
    setPrevKBC3PurchasedAt(props.selectedWish?.prevKBC3PurchasedAt || '');
    setPrevKBC4(props.selectedWish?.prevKBC4 || '');
    setPrevKBC4PurchasedAt(props.selectedWish?.prevKBC4PurchasedAt || '');
    setSinceKBCPurchase(props.selectedWish?.sinceKBCPurchase || '');
    setAmountRTW(props.selectedWish?.amountRTW || '');
    setAmountA(props.selectedWish?.amountA || '');
    setAmountMP(props.selectedWish?.amountMP || '');
    setAmountBW(props.selectedWish?.amountBW || '');
    setAmountZ(props.selectedWish?.amountZ || '');
    setAmountFS(props.selectedWish?.amountFS || '');
    setAmountMOE(props.selectedWish?.amountMOE || '');
    setAmountDOHA(props.selectedWish?.amountDOHA || '');
    setAmountKWT(props.selectedWish?.amountKWT || '');
    setAmountAD(props.selectedWish?.amountAD || '');
    setAmountBAH(props.selectedWish?.amountBAH || '');
    setAmountOther(props.selectedWish?.amountOther || '');
    setNotes(props.selectedWish?.reviewNotes || '');
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorCegidCreatedAt('');
    setErrorTotalPurchase('');
    setErrorAnnualPurchase('');
    setErrorAnnualCPurchase('');
    setErrorPrevAnnualPurchase('');
    setErrorPrevAnnualCPurchase('');
    setErrorPrevKBC1('');
    setErrorPrevKBC1PurchasedAt('');
    setErrorPrevKBC2('');
    setErrorPrevKBC2PurchasedAt('');
    setErrorPrevKBC3('');
    setErrorPrevKBC3PurchasedAt('');
    setErrorPrevKBC4('');
    setErrorPrevKBC4PurchasedAt('');
    setErrorSinceKBCPurchase('');
    setErrorAmountRTW('');
    setErrorAmountA('');
    setErrorAmountMP('');
    setErrorAmountBW('');
    setErrorAmountZ('');
    setErrorAmountFS('');
    setErrorAmountMOE('');
    setErrorAmountDOHA('');
    setErrorAmountKWT('');
    setErrorAmountAD('');
    setErrorAmountBAH('');
    setErrorAmountOther('');
    setErrorNotes('');
  };

  useEffect(() => {
    clearForm();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedGroup, props.selectedWish])

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });

    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleReview();
    }
  };

  const handleReview = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating Wish Details: <strong>${props.selectedWish?.name}</strong> ... ` });
    reviewWish(props.selectedWish.groupId, props.selectedWish.wishId, cegidCreatedAt, totalPurchase, annualPurchase, annualCPurchase, prevAnnualPurchase, prevAnnualCPurchase, prevKBC1, prevKBC1PurchasedAt, prevKBC2, prevKBC2PurchasedAt, prevKBC3, prevKBC3PurchasedAt, prevKBC4, prevKBC4PurchasedAt, sinceKBCPurchase, amountRTW, amountA, amountMP, amountBW, amountZ, amountFS, amountMOE, amountDOHA, amountKWT, amountAD, amountBAH, amountOther, notes, EState.reviewed).then((result: any) => {
      if (result.status) {
        setMessage({ type: 'success', description: `Wish: <strong>${props.selectedWish?.name}</strong> updated <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(result.data);
      } else {
        setMessage({ type: 'error', description: result.message });
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const year = new Date().getFullYear();

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="lg" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <DialogTitle>Review <WishTitle wishType={props.selectedWish?.wishType} /><br /><span className={classes.subtitle}>{props.selectedWish?.clientId}: {props.selectedWish?.name} {props.selectedWish?.name} - {props.selectedWish?.productName} {props.selectedWish?.bag}</span></DialogTitle>
      <DialogContent>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <Grid container spacing={3}>
            <Grid item xs={4}>

              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat='DD MMMM YYYY'
                      label='CEGID created on'
                      value={cegidCreatedAt}
                      onChange={(val: any) => setCegidCreatedAt(val)}
                      renderInput={(params: any) => (
                        <TextField {...params}
                          id='cegidCreatedAt'
                          error={errorCegidCreatedAt.length > 0}
                          helperText={errorCegidCreatedAt}
                          size='small'
                          margin='normal'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='totalPurchase'
                    label='total since creation'
                    value={totalPurchase}
                    error={errorTotalPurchase.length > 0}
                    helperText={errorTotalPurchase}
                    onChange={(e: any) => setTotalPurchase(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    autoFocus
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='annualPurchase'
                    label={`total purchase ${year}`}
                    value={annualPurchase}
                    error={errorAnnualPurchase.length > 0}
                    helperText={errorAnnualPurchase}
                    onChange={(e: any) => setAnnualPurchase(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='annualCPurchase'
                    label={`total C purchase ${year}`}
                    value={annualCPurchase}
                    error={errorAnnualCPurchase.length > 0}
                    helperText={errorAnnualCPurchase}
                    onChange={(e: any) => setAnnualCPurchase(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='prevAnnualPurchase'
                    label={`total purchase ${year - 1}`}
                    value={prevAnnualPurchase}
                    error={errorPrevAnnualPurchase.length > 0}
                    helperText={errorPrevAnnualPurchase}
                    onChange={(e: any) => setPrevAnnualPurchase(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='prevAnnualCPurchase'
                    label={`total C purchase ${year - 1}`}
                    value={prevAnnualCPurchase}
                    error={errorPrevAnnualCPurchase.length > 0}
                    helperText={errorPrevAnnualCPurchase}
                    onChange={(e: any) => setPrevAnnualCPurchase(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <TextField
                id='notes'
                label='notes'
                value={notes}
                error={errorNotes.length > 0}
                helperText={errorNotes}
                onChange={(e: any) => setNotes(e.target.value)}
                size='small'
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows='4'
                inputProps={{ className: classes.notes }}
              />
            </Grid>
            <Grid item xs={4}>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id='sinceKBCPurchase'
                    label='total purchase since "1st previous KB"'
                    value={sinceKBCPurchase}
                    error={errorSinceKBCPurchase.length > 0}
                    helperText={errorSinceKBCPurchase}
                    onChange={(e: any) => setSinceKBCPurchase(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='prevKBC1'
                    label='1st previous KB'
                    value={prevKBC1}
                    error={errorPrevKBC1.length > 0}
                    helperText={errorPrevKBC1}
                    onChange={(e: any) => setPrevKBC1(e.target.value)}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat='DD MMMM YYYY'
                      label='purchased at'
                      value={prevKBC1PurchasedAt}
                      onChange={(val: any) => setPrevKBC1PurchasedAt(val)}
                      renderInput={(params: any) => (
                        <TextField {...params}
                          id='prevKBC1PurchasedAt'
                          error={errorPrevKBC1PurchasedAt.length > 0}
                          helperText={errorPrevKBC1PurchasedAt}
                          size='small'
                          margin='normal'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='prevKBC2'
                    label='2nd previous KB'
                    value={prevKBC2}
                    error={errorPrevKBC2.length > 0}
                    helperText={errorPrevKBC2}
                    onChange={(e: any) => setPrevKBC2(e.target.value)}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat='DD MMMM YYYY'
                      label='purchased at'
                      value={prevKBC2PurchasedAt}
                      onChange={(val: any) => setPrevKBC2PurchasedAt(val)}
                      renderInput={(params: any) => (
                        <TextField {...params}
                          id='prevKBC2PurchasedAt'
                          error={errorPrevKBC2PurchasedAt.length > 0}
                          helperText={errorPrevKBC2PurchasedAt}
                          size='small'
                          margin='normal'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='prevKBC3'
                    label='3rd previous KB'
                    value={prevKBC3}
                    error={errorPrevKBC3.length > 0}
                    helperText={errorPrevKBC3}
                    onChange={(e: any) => setPrevKBC3(e.target.value)}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat='DD MMMM YYYY'
                      label='purchased at'
                      value={prevKBC3PurchasedAt}
                      onChange={(val: any) => setPrevKBC3PurchasedAt(val)}
                      renderInput={(params: any) => (
                        <TextField {...params}
                          id='prevKBC3PurchasedAt'
                          error={errorPrevKBC3PurchasedAt.length > 0}
                          helperText={errorPrevKBC3PurchasedAt}
                          size='small'
                          margin='normal'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='prevKBC4'
                    label='4th previous KB'
                    value={prevKBC4}
                    error={errorPrevKBC4.length > 0}
                    helperText={errorPrevKBC4}
                    onChange={(e: any) => setPrevKBC4(e.target.value)}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat='DD MMMM YYYY'
                      label='purchased at'
                      value={prevKBC4PurchasedAt}
                      onChange={(val: any) => setPrevKBC4PurchasedAt(val)}
                      renderInput={(params: any) => (
                        <TextField {...params}
                          id='prevKBC4PurchasedAt'
                          error={errorPrevKBC4PurchasedAt.length > 0}
                          helperText={errorPrevKBC4PurchasedAt}
                          size='small'
                          margin='normal'
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <TextField
                    id='amountRTW'
                    label='RTW'
                    value={amountRTW}
                    error={errorAmountRTW.length > 0}
                    helperText={errorAmountRTW}
                    onChange={(e: any) => setAmountRTW(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountA'
                    label='A'
                    value={amountA}
                    error={errorAmountA.length > 0}
                    helperText={errorAmountA}
                    onChange={(e: any) => setAmountA(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountMP'
                    label='MP'
                    value={amountMP}
                    error={errorAmountMP.length > 0}
                    helperText={errorAmountMP}
                    onChange={(e: any) => setAmountMP(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountBW'
                    label='BW'
                    value={amountBW}
                    error={errorAmountBW.length > 0}
                    helperText={errorAmountBW}
                    onChange={(e: any) => setAmountBW(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountZ'
                    label='Z'
                    value={amountZ}
                    error={errorAmountZ.length > 0}
                    helperText={errorAmountZ}
                    onChange={(e: any) => setAmountZ(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountFS'
                    label='FS'
                    value={amountFS}
                    error={errorAmountFS.length > 0}
                    helperText={errorAmountFS}
                    onChange={(e: any) => setAmountFS(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountMOE'
                    label='MOE'
                    value={amountMOE}
                    error={errorAmountMOE.length > 0}
                    helperText={errorAmountMOE}
                    onChange={(e: any) => setAmountMOE(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountDOHA'
                    label='DOHA'
                    value={amountDOHA}
                    error={errorAmountDOHA.length > 0}
                    helperText={errorAmountDOHA}
                    onChange={(e: any) => setAmountDOHA(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountKWT'
                    label='KWT'
                    value={amountKWT}
                    error={errorAmountKWT.length > 0}
                    helperText={errorAmountKWT}
                    onChange={(e: any) => setAmountKWT(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountAD'
                    label='AD'
                    value={amountAD}
                    error={errorAmountAD.length > 0}
                    helperText={errorAmountAD}
                    onChange={(e: any) => setAmountAD(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountBAH'
                    label='BAH'
                    value={amountBAH}
                    error={errorAmountBAH.length > 0}
                    helperText={errorAmountBAH}
                    onChange={(e: any) => setAmountBAH(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id='amountOther'
                    label='Other'
                    value={amountOther}
                    error={errorAmountOther.length > 0}
                    helperText={errorAmountOther}
                    onChange={(e: any) => setAmountOther(e.target.value.trim())}
                    size='small'
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <AppMessage message={message} isLoading={isLoading} />
            </Grid>
          </Grid>

        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
