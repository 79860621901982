import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles: any = makeStyles()((theme) => ({
  table: {
    width: '100%',
  },
  heading: {
    fontWeight: 400,
    color: '#666',
  },
  cell: {
    padding: theme.spacing(1, 2),
  },
  label: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right'
  },
  stateLabel: {
    fontSize: '0.85em',
    fontWeight: 500,
    textAlign: 'right',
    marginTop: theme.spacing(0.5)
  },
  text: {
    fontSize: '0.85em',
    fontWeight: 400,
  },
  textLrg: {
    fontSize: '1.2em',
    fontWeight: 700,
  },
  number: {
    fontSize: '0.85em',
    fontWeight: 400,
    fontFamily: 'Roboto Mono',
  },
}));

export const WishProduct = (props: any) => {
  const { selectedWish } = props;

  const { classes } = useStyles();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.cell}><div className={classes.heading}>Product</div></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow component="tr">
          <TableCell className={classes.cell} style={{borderBottom: 'none'}}>
            <Grid container spacing={1} alignItems="center" style={classes.grid}>
              <Grid item xs={5} className={classes.label}></Grid><Grid item xs={7} className={classes.text}></Grid>
              {selectedWish.productApprovedBy && (<><Grid item xs={5} className={classes.label}>Approval Required: </Grid><Grid item xs={7} className={classes.text}>{selectedWish.productApprovedBy}</Grid></>)}
              {selectedWish.approveUserName && (<><Grid item xs={5} className={classes.label}>Approved By: </Grid><Grid item xs={7} className={classes.text}>{selectedWish.approveUserName}</Grid></>)}
              <Grid item xs={5} className={classes.label}></Grid><Grid item xs={7} className={classes.text}></Grid>
              {selectedWish.productSku && (<><Grid item xs={5} className={classes.label}>SKU: </Grid><Grid item xs={7} className={classes.text}>{selectedWish.productSku}</Grid></>)}
              {selectedWish.productName && (<><Grid item xs={5} className={classes.label}>Name: </Grid><Grid item xs={7} className={classes.textLrg}>{selectedWish.productName} {selectedWish.bag}</Grid></>)}
              {selectedWish.price && (<><Grid item xs={5} className={classes.label}>Price: </Grid><Grid item xs={7} className={classes.number}>{selectedWish.price}</Grid></>)}
              <Grid item xs={5} className={classes.label}></Grid><Grid item xs={7} className={classes.text}></Grid>
              {selectedWish.suprise && (<><Grid item xs={5} className={classes.label}>Open to Suprise: </Grid><Grid item xs={7}>{selectedWish.suprise}</Grid></>)}
              <Grid item xs={5} className={classes.label}></Grid><Grid item xs={7} className={classes.text}></Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
