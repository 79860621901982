// import React, { createContext, useState, useEffect, useCallback } from "react";
import React, { createContext, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useSnackbar } from 'notistack';

// import { useAuthStore } from "../hooks/context/useAuthStore";

// import { Button } from '@mui/material';

interface INotifyContext {
  newTrades: any[];
};

const initialState: INotifyContext = {
  newTrades: []
};

export const NotifyContext = createContext<INotifyContext>(initialState);

export const NotifyProvider = ({ children }: { children: React.ReactElement }) => {
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // const { currentUser } = useAuthStore();
  // const navigate = useNavigate();

  // const [newTrades, setNewTrades] = useState<any[]>([]);
  const [newTrades, ] = useState<any[]>([]);

  // const notify = useCallback((trade: any) => {
    // console.log('notify', trade);
    // const action = (snackbarId: any) => (<Button variant="contained" color='info' size="small" onClick={() => { closeSnackbar(snackbarId); handleTrade(trade.tradeId); }}> Trade </Button>);
    // if (trade.offerUserId === currentUser.userId) {
    //   enqueueSnackbar(`New Trade Request: Sell ${trade.amount.toFixed(2)} ${trade.symbolSend} ( ${(trade.amount * trade.rate).toFixed(2)} ${trade.symbolRecv} )`, { action, anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    // } else {
    //   enqueueSnackbar(`New Trade: Buy ${trade.amount.toFixed(2)} ${trade.symbolSend} ( ${(trade.amount * trade.rate).toFixed(2)} ${trade.symbolRecv} )`, { action, anchorOrigin: { horizontal: 'right', vertical: 'top' } });
    // }
  // }, [currentUser, closeSnackbar, enqueueSnackbar]);

  // useEffect(() => {
  //   const newNotify = newTrades.filter((x: any) => !x.isNotify);
  //   if (newNotify.length > 0) {
  //     newNotify.map((x: any) => notify(x));
  //     const data = newTrades.map((x: any) => Object.assign({}, x, { isNotify: true }));
  //     setNewTrades(data);
  //   }
  // }, [newTrades, notify]);


  const value = { newTrades };
  return (
    <NotifyContext.Provider value={value}>
      {children}
    </NotifyContext.Provider>
  );
  
};