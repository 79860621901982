import { useState, useEffect } from 'react';

import { changePassword } from '../../../services/users';

import { AppMessage } from '../../../shared/UI/AppMessage';

// import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityOnIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// const useStyles = makeStyles()((theme) => ({
//   formcontrol: {
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(1),
//   },
//   helper: {
//     marginBottom: theme.spacing(2)
//   },
//   alert: {
//     marginTop: theme.spacing(1),
//     marginBottom: theme.spacing(1),
//     backgroundColor: theme.palette.background.paper
//   },
//   small: {
//     fontWeight: 400,
//   },
//   buttons: {
//     marginTop: theme.spacing(3),
//     marginBottom: theme.spacing(3),
//   }
// }));

export const ResetPasswordModal = (props: any) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [errorUsername, setErrorUsername] = useState('');
  const [errorPassword, setErrorPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  
  useEffect(() => {
    clearForm();
    clearErrors();
    setUsername(props.selectedUser?.username);
  }, [props.selectedUser, props.open])

  const clearForm = () => {
    setUsername('');
    setPassword('');
    setShowPassword(false);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorUsername('');
    setErrorPassword('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (password.length === 0) {
      setErrorPassword('Enter the password');
      return false;
    }
    if (password.length < 8) {
      setErrorPassword('Enter a password with a minimum of 8 characters');
      return false;
    }
    if (!password.match(/[a-z]+/)) {
      setErrorPassword('Enter a password with a lower case character');
      return false;
    }
    if (!password.match(/[A-Z]+/)) {
      setErrorPassword('Enter a password with an upper case character');
      return false;
    }
    if (!password.match(/[0-9]+/)) {
      setErrorPassword('Enter a password with a numeric character');
      return false;
    }
    if (!password.match(/^[a-zA-Z0-9]+/)) {
      setErrorPassword('Enter a password with a special character');
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      handleAddNew();
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Changing the password for user: <strong>${props.selectedUser?.username}</strong> ... ` });
    changePassword(props.selectedUser.userId, password).then((data: any) => {
      console.log('changePassword', data);
      if (data) {
        setMessage({ type: 'success', description: `The password for user: <strong>${props.selectedUser?.username}</strong> has been changed <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit();
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  // const { classes } = useStyles();
  return (
    <Dialog maxWidth="sm" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <DialogTitle>Reset User's Password</DialogTitle>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Complete the following details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <TextField
            id='username'
            label='username'
            value={username}
            error={errorUsername.length > 0}
            helperText={errorUsername}
            onChange={(e: any) => setUsername(e.target.value.trim())}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            disabled
          />

          <TextField
            id='password'
            label='password'
            value={password}
            error={errorPassword.length > 0}
            helperText={errorPassword}
            onChange={(e: any) => setPassword(e.target.value)}
            margin='normal'
            variant='outlined'
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}            
          />
        <AppMessage message={message} isLoading={isLoading} />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog >
  );
};
