import useSWR from 'swr';

import {useAuthStore} from "../context/useAuthStore";
import { getGroups } from "../../services/auth";


const options = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  shouldRetryOnError: false,
};

const apiMyGroupList = ([_]: any[]) => getGroups();

export const useApiMyGroupList = () => {
  const { isAuthorized } = useAuthStore();
  const { data, error, isValidating, mutate } = useSWR(isAuthorized ? ['useApiMyGroupList'] : null, apiMyGroupList, options)
  return {
    data: data?.status ? data.data : undefined,
    isLoading: isValidating,
    error: error,
    mutate: mutate
  }
};

