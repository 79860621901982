import { useState, useEffect, useMemo } from 'react';
import { useAuthStore } from '../../hooks/context/useAuthStore';
import { useApiMyGroupList } from '../../hooks/auth/useApiMyGroupList';
import { useApiGroupUserList } from '../../hooks/admin/useApiGroupUserList';
import { useApiUserList } from '../../hooks/admin/useApiUserList';
import { useApiProductList } from '../../hooks/api/useApiProductList';
import { doCreateWish, doUpdateWish } from '../../services/wish';

import { WishTitle } from '../Wish/WishTitle';
import { AppMessage } from '../UI/AppMessage';

import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles()((theme) => ({
  subtitle: {
    fontWeight: 300,
    fontSize: '1em',
  },
  buttonItem: {
    textAlign: 'right',
    paddingRight: theme.spacing(3)
  },
  formcontrol: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  radioTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    fontSize: '0.72em'
  },
  radioLabel: {
    fontSize: '0.72em'
  },
  errorText: {
    color: theme.palette.error.dark,
    marginTop: theme.spacing(0.5)
  },
}));

export const EditWishModal = (props: any) => {
  const { currentUser } = useAuthStore();
  const { data: dataGroupList, isLoading: isLoadingGroupList, mutate: mutateGroupList } = useApiMyGroupList();
  const { data: dataGroupUserList, isLoading: isLoadingGroupUserList, mutate: mutateGroupUserList } = useApiGroupUserList();
  const { data: dataUserList, isLoading: isLoadingUserList, mutate: mutateUserList } = useApiUserList();
  const { data: dataProductList, isLoading: isLoadingProductList, mutate: mutateProductList } = useApiProductList();

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [userId, setUserId] = useState('');
  const [productCategory, setProductCategory] = useState('');
  const [productId, setProductId] = useState('');
  const [approvedBy, setApprovedBy] = useState('');
  const [bag, setBag] = useState('');
  const [suprise, setSuprise] = useState('');
  const [notes, setNotes] = useState('');
  const [highlights, setHighlights] = useState('');
  const [status, setStatus] = useState(true);

  const [errorGroupId, setErrorGroupId] = useState('');
  const [errorClientId, setErrorClientId] = useState('');
  const [errorName, setErrorName] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPhone, setErrorPhone] = useState('');
  const [errorUserId, setErrorUserId] = useState('');
  const [errorProductCategory, setErrorProductCategory] = useState('');
  const [errorProductId, setErrorProductId] = useState('');
  const [errorApprovedBy, setErrorApprovedBy] = useState('');
  const [errorBag, setErrorBag] = useState('');
  const [errorSuprise, setErrorSuprise] = useState('');
  const [errorNotes, setErrorNotes] = useState('');
  const [errorHighlights, setErrorHighlights] = useState('');

  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: 'info', description: '' });

  const users = useMemo(() => dataUserList?.filter((user: any) => dataGroupUserList?.some((groupuser: any) => groupuser.groupId === groupId && groupuser.userId === user.userId)), [groupId, dataGroupUserList, dataUserList]);

  const selectedProduct = useMemo(() => dataProductList?.find((x: any) => x.productId === productId), [dataProductList, productId]);
  useEffect(() => setApprovedBy(selectedProduct?.approvedBy || ''), [selectedProduct]);

  useEffect(() => {
    clearForm();
    clearErrors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedGroup, props.selectedWish])

  const clearForm = () => {
    setGroupId(props.selectedWish?.groupId || props.selectedGroup?.groupId || '');
    setClientId(props.selectedWish?.clientId || '');
    setName(props.selectedWish?.name || '');
    setEmail(props.selectedWish?.email || '');
    setPhone(props.selectedWish?.phone || '');
    setUserId(props.selectedWish?.advisorUserId || currentUser?.userId || '');
    setProductCategory(props.selectedWish?.productCategory || '');
    setProductId(props.selectedWish?.productId || '');
    setApprovedBy(props.selectedWish?.approvedBy || props.selectedWish?.productApprovedBy || '');
    setBag(props.selectedWish?.bag || '');
    setSuprise(props.selectedWish?.suprise || '');
    setNotes(props.selectedWish?.advisorNotes || '');
    setHighlights(props.selectedWish?.advisorHighlights || '');
    setStatus(props.selectedWish?.status ?? true);
  };

  const clearErrors = () => {
    setMessage({ type: 'info', description: '' });
    setErrorGroupId('');
    setErrorClientId('');
    setErrorName('');
    setErrorEmail('');
    setErrorPhone('');
    setErrorUserId('');
    setErrorProductCategory('');
    setErrorProductId('');
    setErrorApprovedBy('');
    setErrorBag('');
    setErrorSuprise('');
    setErrorNotes('');
    setErrorHighlights('');
  };

  const validateForm = () => {
    clearErrors();
    setMessage({ type: 'error', description: 'Please correct your details ...' });
    if (groupId?.length === 0) {
      setErrorGroupId(`Select the Group`);
      return false;
    }
    if (userId?.length === 0) {
      setErrorUserId(`Select the Advisor`);
      return false;
    }
    if (name?.length === 0) {
      setErrorName(`Enter the client's name`);
      return false;
    }
    if (productId?.length === 0) {
      setErrorProductId(`Select the Product`);
      return false;
    }
    setMessage({ type: 'info', description: '' });
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      if (props.selectedWish?.wishId) {
        handleUpdate();
      } else {
        handleAddNew();
      }
    }
  };

  const handleAddNew = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Creating Leather Wish for: <strong>${name}</strong> ... ` });
    doCreateWish(props.selectedGroup?.groupId, props.newWishType, clientId, name.trim(), email, phone, status, userId, notes.trim(), highlights.trim(), productId, approvedBy, bag.trim(), suprise.trim()).then((data: any) => {
      console.log('createWish', data);
      if (data) {
        setMessage({ type: 'success', description: `Leather Wish: <strong>${name}</strong> created <small>at ${new Date().toString()}</small>` });
        clearErrors();
        clearForm();
        props.handleSubmit(data.groupId);
      }
    }).catch((e: any) => {
      console.log(e);
      if (e.message) {
        setMessage({ type: 'error', description: e.message });
      } else {
        setMessage({ type: 'error', description: e });
      }
    }).finally(() => setLoading(false));
  };

  const handleUpdate = () => {
    setLoading(true);
    setMessage({ type: 'info', description: `Updating Leather Wish: <strong>${name}</strong> ... ` });
    doUpdateWish(props.selectedWish?.groupId, props.selectedWish?.wishId, clientId, name.trim(), email, phone, status, userId, notes.trim(), highlights.trim(), productId, approvedBy, bag.trim(), suprise.trim()).then((data: any) => {
      console.log('updateWish', data);
      if (data) {
        setMessage({ type: 'success', description: `Leather Wish: <strong>${name}</strong> updated <small>at ${new Date().toString()}</small>` });
        setLoading(false);
        props.handleSubmit(props.selectedWish?.groupId, props.selectedWish?.wishId);
        clearErrors();
        clearForm();
      }
    }).catch((e: any) => {
      console.log(e);
      setMessage({ type: 'error', description: e.message });
      setLoading(false);
    });
  };

  const handleCancel = () => {
    props.closeModal();
    clearErrors();
    clearForm();
  };

  const handleMutate = () => {
    mutateGroupList();
    mutateGroupUserList();
    mutateUserList();
    mutateProductList();
  };

  const isLoadingData = (isLoadingGroupList || isLoadingGroupUserList || isLoadingUserList || isLoadingProductList);

  const { classes } = useStyles();
  return (
    <Dialog maxWidth="lg" fullWidth open={props.open} onClose={() => props.closeModal()}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {props.selectedWish?.wishId && (
            <DialogTitle>Update a <WishTitle wishType={props.selectedWish?.wishType} /></DialogTitle>
          )}
          {!props.selectedWish?.wishId && (
            <DialogTitle>Create a new <WishTitle wishType={props.newWishType} /></DialogTitle>
          )}
        </Grid>
        <Grid item className={classes.buttonItem}>
          {isLoadingData && (
            <Tooltip title="loading"><IconButton><CircularProgress color="primary" size={24} /></IconButton></Tooltip>
          )}
          {!isLoading && (
            <Tooltip title="refresh"><IconButton color="primary" onClick={() => handleMutate()}><RefreshIcon /></IconButton></Tooltip>
          )}
        </Grid>
      </Grid>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <DialogContentText>Enter the wish details:</DialogContentText>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>

          <Grid container spacing={6}>
            <Grid item xs={6}>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl className={classes.formcontrol} fullWidth>
                    <InputLabel id='groupId-label'>group</InputLabel>
                    <Select
                      labelId='groupId-label'
                      id='groupId'
                      label="group"
                      value={groupId}
                      onChange={(e: any) => setGroupId(e.target.value)}
                      disabled={!!props.selectedWish?.groupId}
                    >
                      {dataGroupList?.map((group: any) => (
                        <MenuItem key={group.groupId} value={group.groupId}>{group.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className={classes.errorText}>{errorGroupId}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formcontrol} fullWidth>
                    <InputLabel id='userId-label'>advisor</InputLabel>
                    <Select
                      labelId='userId-label'
                      id='userId'
                      label="advisor"
                      value={userId}
                      onChange={(e: any) => setUserId(e.target.value)}
                    >
                      {users?.map((user: any) => (
                        <MenuItem key={user.userId} value={user.userId}>{user.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className={classes.errorText}>{errorUserId}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <TextField
                id='name'
                label='client name'
                value={name}
                error={errorName.length > 0}
                helperText={errorName}
                onChange={(e: any) => setName(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                autoFocus
              />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    id='clientId'
                    label='CEGID No'
                    value={clientId}
                    error={errorClientId.length > 0}
                    helperText={errorClientId}
                    onChange={(e: any) => setClientId(e.target.value)}
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='phone'
                    label='phone'
                    value={phone}
                    error={errorPhone.length > 0}
                    helperText={errorPhone}
                    onChange={(e: any) => setPhone(e.target.value)}
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <TextField
                id='email'
                label='email'
                value={email}
                error={errorEmail.length > 0}
                helperText={errorEmail}
                onChange={(e: any) => setEmail(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <AppMessage message={message} isLoading={isLoading} />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl className={classes.formcontrol} fullWidth>
                    <InputLabel id='productCategory-label'>category</InputLabel>
                    <Select
                      labelId='productCategory-label'
                      id='productCategory'
                      label="category"
                      value={productCategory}
                      onChange={(e: any) => setProductCategory(e.target.value)}
                    >
                      <MenuItem value={'kelly'}>Kelly Classic</MenuItem>
                      <MenuItem value={'kellyx'}>Kelly Exotic</MenuItem>
                      <MenuItem value={'birkin'}>Birkin Classic</MenuItem>
                      <MenuItem value={'birkinx'}>Birkin Exotic</MenuItem>
                    </Select>
                    <FormHelperText className={classes.errorText}>{errorProductCategory}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl className={classes.formcontrol} fullWidth>
                    <InputLabel id='productId-label'>product</InputLabel>
                    <Select
                      labelId='productId-label'
                      id='productId'
                      label="advisor"
                      value={productId}
                      onChange={(e: any) => setProductId(e.target.value)}
                    >
                      {dataProductList?.filter((x: any) => x.category === productCategory).sort((a: any, b: any) => a.name.localeCompare(b.name)).map((product: any) => (
                        <MenuItem key={product.productId} value={product.productId}>{product.name}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText className={classes.errorText}>{errorProductId}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    id='bag'
                    label='bag details'
                    value={bag}
                    error={errorBag.length > 0}
                    helperText={errorBag}
                    onChange={(e: any) => setBag(e.target.value)}
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id='suprise'
                    label='open to suprise'
                    value={suprise}
                    error={errorSuprise.length > 0}
                    helperText={errorSuprise}
                    onChange={(e: any) => setSuprise(e.target.value)}
                    margin='normal'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <FormControl>
                <FormLabel id="approvedBy-label" className={classes.radioTitle}>Requires Approval By</FormLabel>
                <RadioGroup
                  id='approvedBy'
                  name="approvedBy"
                  value={approvedBy}
                  onChange={(e: any) => setApprovedBy(e.target.value)}
                  row
                >
                  <FormControlLabel value="director" control={<Radio size="small" />} label="Store Director" disabled={selectedProduct?.approvedBy === 'director'} />
                  <FormControlLabel value="manager" control={<Radio size="small" />} label="Leather Manager" className={classes.radioLabel} disabled={selectedProduct?.approvedBy === 'director'} />
                  <FormControlLabel value="metier" control={<Radio size="small" />} label="Métier Line Manager" className={classes.radioLabel} disabled={selectedProduct?.approvedBy === 'director'} />
                </RadioGroup>
                <FormHelperText className={classes.errorText}>{errorApprovedBy}</FormHelperText>
              </FormControl>
              <TextField
                id='notes'
                label='customer insights'
                value={notes}
                error={errorNotes.length > 0}
                helperText={errorNotes}
                onChange={(e: any) => setNotes(e.target.value)}
                margin='normal'
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                fullWidth
                multiline
                rows='2'
              />
              {props.newWishType === 'appointment' && (
                <TextField
                  id='highlights'
                  label='highlights of the last visit'
                  value={highlights}
                  error={errorHighlights.length > 0}
                  helperText={errorHighlights}
                  onChange={(e: any) => setHighlights(e.target.value)}
                  margin='normal'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  multiline
                  rows='2'
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color='primary' disabled={isLoading}>Cancel</Button>
        <Button onClick={() => handleSubmit()} color='primary' variant='contained' disabled={isLoading} disableElevation>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
