import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../hooks/context/useAuthStore';
import { useWishStore } from '../../hooks/context/useWishStore';

import { EState } from '../../services/types';

import { AppLayout } from '../../shared/AppLayout/AppLayout';
import { GroupTable } from '../../shared/Table/GroupTable';
import { WishTable } from '../../shared/Table/WishTable';
import { WishDetails } from '../../shared/Wish/WishDetails';

import { makeStyles } from 'tss-react/mui';
import { Paper } from '@mui/material';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const useStyles = makeStyles()((theme) => ({
  root: {
  },
  skeleton: {
    height: '250px',
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1, 2, 2, 2),
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: 'Roboto Slab',
    fontSize: '1.75rem',
    fontWeight: 500,
  },
  subtitle: {
    marginBlockStart: 0,
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    fontWeight: 300,
    '& span': {
      fontWeight: 500
    }
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}));

export const Overview = () => {
  const { currentUser, isAuthorized } = useAuthStore();
  const { dataGroupList, dataWishList, selectedGroup, selectGroup, selectedWish, selectWish, isLoading, mutate, handleCreate, handleEdit, handleDelete, handleSubmit, handleReview, handleApprove, handleSchedule, handleDeliver, handleCancel, handleState, handlePanel } = useWishStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate('/signin');
    }
  }, [isAuthorized, navigate]);

  const handleMore = (advisorUserId: string, state: string) => {
    selectWish('');
    navigate(`/wish#advisorUserId=${advisorUserId}&state=${state}`);
  }

  const handleWish = (id: string) => {
    console.log('handleWish', id);
    selectWish(id);
    if (selectedWish) navigate(`/wish/${selectedWish.groupId}/${selectedWish.wishId}`);
  }

  const { classes } = useStyles();
  return (
    <AppLayout>
      <Grid container justifyContent="space-between">
        <Grid item>
          <div className={classes.root}>
            <h1 className={classes.title}>Overview</h1>
            <h4 className={classes.subtitle}><span>View</span> &amp; <span>Schedule</span> Leather Wishes</h4>
          </div>
        </Grid>
        <Grid item style={{ paddingTop: '40px' }}>
          <Button size="small" variant="contained" color="primary" disableElevation endIcon={<LibraryBooksIcon />} className={classes.button} onClick={() => handleCreate('appointment')}>Create Appointment</Button>
          <Button size="small" variant="contained" color="primary" disableElevation endIcon={<PhotoFilterIcon />} className={classes.button} onClick={() => handleCreate('wish')}>Create Wish</Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={3}>
          <Paper className={classes.paper}>
            <GroupTable data={dataGroupList} isLoading={isLoading} mutate={mutate} selectGroup={selectGroup} selectedGroup={selectedGroup} />
          </Paper>
          {selectedWish?.wishId && (
            <Paper className={classes.paper}>
              <WishDetails
                selectedWish={selectedWish}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleSubmit={handleSubmit}
                handleReview={handleReview}
                handleApprove={handleApprove}
                handleSchedule={handleSchedule}
                handleDeliver={handleDeliver}
                handleCancel={handleCancel}
                handleState={handleState}
                handlePanel={handlePanel}
                handleWish={handleWish}
                isLoading={isLoading}
                mutate={mutate}
              />
            </Paper>
          )}
        </Grid>
        <Grid item sm={9}>
          <Grid container spacing={3}>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="My Wishes" subtitle={currentUser.name} data={dataWishList?.filter((x: any) => x.advisorUserId === currentUser.userId)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore(currentUser.userId, '')} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="To Submit" subtitle="by Line Manager" data={dataWishList?.filter((x: any) => x.state === EState.created)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.created)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="To Review" subtitle="by SSS Team" data={dataWishList?.filter((x: any) => x.state === EState.submitted)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.submitted)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="To Approve" subtitle="by Leather Manager" data={dataWishList?.filter((x: any) => x.state === EState.reviewed)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.reviewed)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="To Schedule" subtitle="by Leather Manager" data={dataWishList?.filter((x: any) => x.state === EState.approved)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.approved)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="To Deliver" subtitle="by Cashier" data={dataWishList?.filter((x: any) => x.state === EState.scheduled)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.scheduled)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="Completed" data={dataWishList?.filter((x: any) => x.state === EState.completed)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.completed)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="Cancelled" data={dataWishList?.filter((x: any) => x.state === EState.cancelled)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.cancelled)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="Expired" data={dataWishList?.filter((x: any) => x.state === EState.expired)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.failed)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="Rejected" data={dataWishList?.filter((x: any) => x.state === EState.rejected)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.rejected)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="Failed" data={dataWishList?.filter((x: any) => x.state === EState.failed)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.completed)} />
              </Paper>
            </Grid>
            <Grid item sm={3}>
              <Paper className={classes.paper}>
                <WishTable title="Denied" data={dataWishList?.filter((x: any) => x.state === EState.denied)} isLoading={isLoading} mutate={mutate} selectWish={selectWish} selectedWish={selectedWish} selectedGroup={selectedGroup} handleOpen={handlePanel} handleMore={() => handleMore('', EState.denied)} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppLayout>
  );
};
